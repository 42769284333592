import { Box, Button, LinearProgress, Typography } from "@mui/material";
import {
  ElementRef,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Navigate, useParams, useRouteLoaderData } from "react-router";
import { getInsightsEnv } from "../../utils/getInsightsEnv";
import { getCKEditorKey } from "../../utils/getCKEditorKey";
import { AbxUploadAdapterPlugin } from "../report-builder/plugins/AbxUploadAdapter";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { FilesWidgetButton } from "../report-builder/plugins/FilesWidget.plugin";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Autosave } from "@ckeditor/ckeditor5-autosave";
import {
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from "@ckeditor/ckeditor5-basic-styles";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
import {
  DocumentOutline,
  TableOfContents,
} from "@ckeditor/ckeditor5-document-outline";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from "@ckeditor/ckeditor5-font";
import { FormatPainter } from "@ckeditor/ckeditor5-format-painter";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { HorizontalLine } from "@ckeditor/ckeditor5-horizontal-line";
import { HtmlEmbed } from "@ckeditor/ckeditor5-html-embed";
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageUtils,
} from "@ckeditor/ckeditor5-image";
import { Indent, IndentBlock } from "@ckeditor/ckeditor5-indent";
import { Mention, MentionFeedObjectItem } from "@ckeditor/ckeditor5-mention";
import { List } from "@ckeditor/ckeditor5-list";
import { PageBreak } from "@ckeditor/ckeditor5-page-break";
import { RemoveFormat } from "@ckeditor/ckeditor5-remove-format";
import { SlashCommand } from "@ckeditor/ckeditor5-slash-command";
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from "@ckeditor/ckeditor5-special-characters";
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from "@ckeditor/ckeditor5-table";
import { Template } from "@ckeditor/ckeditor5-template";
import { encodeText } from "../../utils/encodeText";
import { sanitizeXSS } from "../../utils/xss";
import { api } from "../api";
import { InsightsWidgetButton } from "../report-builder/plugins/InsightsWidget.plugin";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { Pagination } from "@ckeditor/ckeditor5-pagination";
import { Link as LinkPlugin } from "@ckeditor/ckeditor5-link";
import { EditorConfig, icons } from "@ckeditor/ckeditor5-core";
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import { OrganizationResponse, UserResponse } from "@akitabox/api-client";
import { useShellDOM } from "../hooks/useShellDOM";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { DecoupledEditor } from "@ckeditor/ckeditor5-editor-decoupled";
import {
  DashboardModel,
  InsightsTemplateSelection,
} from "../report-builder/insights/types";
import { decodeText } from "../../utils/decodeText";
import { AttachmentBuildingDialog } from "../attachment-building-dialog/AttachmentBuildingDialog";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { stylesheet } from "../stylesheet";
import { Color } from "../colors";
import InsightsService from "../services/InsightsService";
import { ReportBlockPromptDialog } from "../report-block-prompt/dialogs/ReportBlockPromptDialog";
import { ReportBlockPromptWidgets } from "../report-block-prompt/ReportBlockPromptWidgets.plugin";
import { InsightsBlockPromptWidgets } from "../report-block-prompt/InsightsBlockPromptWidgets.plugin";
import { InsightsPromptSelectionDialog } from "../report-block-prompt/dialogs/InsightsPromptSelectionDialog";
import SubjectIcon from "@mui/icons-material/Subject";
import { GenerateReportDialog } from "../generate-report-dialog/GenerateReportDialog";
import { uid } from "@ckeditor/ckeditor5-utils";
import { MentionCustomization } from "../ck-editor/plugins/MentionCustomization.plugin";
import {
  PageMargins,
  ReportMarginsDialog,
} from "../report-margins-dialog/ReportMarginsDialog";
import { PageMarginsButton } from "../ck-editor/plugins/PageMarginsButton.plugin";
import { FooterButton } from "../ck-editor/plugins/FooterButton.plugin";
import { ReportFooterDialog } from "../report-footer-dialog/ReportFooterDialog";
import { ReportHeaderDialog } from "../report-header-dialog/ReportHeaderDialog";
import { HeaderButton } from "../ck-editor/plugins/HeaderButton.plugin";
import {
  buildingFeedItems,
  buildingRPIFCIFeedItems,
  getAllCustomFieldFeedItems,
} from "../ck-editor/plugins/abxMentionFeedItems";

const INSIGHTS_ENV = getInsightsEnv();
const CK_EDITOR_LICENSE_KEY = getCKEditorKey();

export type AbxMentionFeedItem = MentionFeedObjectItem & { field?: string };

export const customMentionItemRenderer = (item: AbxMentionFeedItem) => {
  const itemElement = document.createElement("span");

  itemElement.classList.add("abx-merge-field");
  itemElement.id = `abx-merge-field-id-${uid()}`;
  itemElement.textContent = `${item.id} `;

  return itemElement;
};

export const ReportTemplateEditor: FunctionComponent = () => {
  const { contentRef } = useShellDOM();
  const { simple } = useGlobalSnackbar();
  const { template: reportTemplateId = "" } = useParams();
  const { organization, user, flags } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
    user: UserResponse;
    flags: { [key: string]: boolean };
  };
  const reportNameRef = useRef<ElementRef<"span">>(null);
  const reportContextRef = useRef<string>("");

  const isEnabled = flags["report_template"];
  const isConfigurableHeaderAndFooterEnabled =
    flags["configurable_header_and_footer"];

  const organizationId = organization._id;

  const {
    data: reportTemplateResponse,
    error: reportError,
    mutate: updateReportTemplate,
  } = makeUseServiceCall(api.reportTemplates.getById)({
    organizationId,
    reportTemplateId,
  });

  const {
    data: contentResponse,
    error: ContentError,
    isValidating: isLoadingContent,
  } = makeUseServiceCall(api.reportTemplateContent.getById)({
    organizationId,
    reportTemplateId,
  });

  const { report_builder } = user.permission_group;
  const reportBuilderPermissions = useMemo(
    () => ({ read: report_builder["read"], update: report_builder["update"] }),
    [report_builder]
  );

  // Dialogs
  const [showReportBlockPromptDialog, setShowReportBlockPromptDialog] =
    useState<boolean>(false);
  const [showGenerateReportDialog, setShowGenerateReportDialog] =
    useState<boolean>(false);

  // Sisense stuff
  const [sisenseApp, setSisenseApp] = useState<any>(null);
  const [dashboards, setDashboards] = useState<DashboardModel[]>();
  const [selectedDashboard, setSelectedDashboard] = useState<string>("");
  const [showInsightsDialog, setShowInsightsDialog] = useState<boolean>(false);

  const [toolbarRef, setToolbarRef] = useState<ElementRef<"div"> | null>(null);
  const [showFilesDialog, setShowFilesDialog] = useState<boolean>(false);

  // header and footer
  const [showFooterDialog, setShowFooterDialog] = useState<boolean>(false);
  const [showHeaderDialog, setShowHeaderDialog] = useState<boolean>(false);

  // margins
  const [showPageMarginsDialog, setShowPageMarginsDialog] =
    useState<boolean>(false);
  const [pageMargins, setPageMargins] = useState<PageMargins>({
    top: 12,
    right: 12,
    bottom: 12,
    left: 12,
    unit: "mm",
  });
  const contentStyle = useMemo(() => {
    const padding = `${pageMargins.top}${pageMargins.unit} ${pageMargins.right}${pageMargins.unit} ${pageMargins.bottom}${pageMargins.unit} ${pageMargins.left}${pageMargins.unit}`;

    return stylesheet({
      container: (theme) => ({
        gridArea: "Content",
        overflowY: "auto",
        padding: theme.spacing(4),
        ".ck.ck-content.ck-editor__editable": {
          padding,
        },
        ".ck-editor__editable_inline": {
          /* Set the dimensions of the "page". */
          width: "216mm",
          minHeight: "297mm",
          /* Keep the "page" off the boundaries of the container. */
          padding: "20mm 12mm",
          border: "1px hsl( 0,0%,82.7% ) solid",
          borderRadius: "var(--ck-border-radius)",
          backgroundColor: "white",
          boxSizing: "border-box",
          /* The "page" should cast a slight shadow (3D illusion). */
          boxShadow: "0 0 5px hsla( 0,0%,0%,.1 )",
          /* Center the "page". */
          margin: "0 auto",
          /* Force all images in ck-editor to conform to the width of the page, this helps mostly with exporting to pdf */
          ".image.ck-widget img": {
            maxWidth: "191mm",
            height: "auto",
          },
          ".raw-html-embed__content-wrapper": {
            resize: "both",
          },
          /* Workaround to contain asset images within the same aspect ratio */
          ".asset-image-contained img": {
            aspectRatio: "4 / 3",
            objectFit: "contain",
          },
        },
      }),
    });
  }, [pageMargins]);

  // ckeditor5 stuff
  const [documentOutlineRef, setDocumentOutlineRef] =
    useState<ElementRef<"div"> | null>(null);
  const [editor, setEditor] = useState<DecoupledEditor | null>(null);
  const [editorData, setEditorData] = useState<string>("");
  const [editorToolbar, setEditorToolbar] = useState<HTMLElement | null>(null);

  const customItemRenderer = useCallback((item: AbxMentionFeedItem) => {
    const itemElement = document.createElement("span");

    itemElement.classList.add("abx-merge-field");
    itemElement.id = `abx-merge-field-id-${uid()}`;
    itemElement.textContent = `${item.id} `;

    return itemElement;
  }, []);
  const getFeedItems = useCallback(
    async (queryText: string) => {
      const items: AbxMentionFeedItem[] = [];

      if (reportContextRef.current === "building") {
        items.push(...buildingFeedItems);

        if (organization.show_rpi_fci) {
          items.push(...buildingRPIFCIFeedItems);
        }

        const customBuildingFieldFeedItems = await getAllCustomFieldFeedItems(
          organizationId,
          "building"
        );
        items.push(...customBuildingFieldFeedItems);
      }

      const customOrganizationFieldFeedItems = await getAllCustomFieldFeedItems(
        organizationId,
        "organization"
      );
      items.push(...customOrganizationFieldFeedItems);

      return items.filter((item) => {
        return item.id.toLowerCase().includes(queryText.toLowerCase());
      });
    },
    [organization.show_rpi_fci, organizationId]
  );

  const [editorConfig] = useState<EditorConfig>({
    plugins: [
      AbxUploadAdapterPlugin,
      Alignment,
      Autoformat,
      Autosave,
      AutoImage,
      Bold,
      BlockQuote,
      CloudServices,
      DocumentOutline,
      Essentials,
      FilesWidgetButton,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FooterButton,
      FormatPainter,
      HeaderButton,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlEmbed,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      Indent,
      IndentBlock,
      InsightsWidgetButton,
      InsightsBlockPromptWidgets,
      Italic,
      LinkPlugin,
      List,
      Mention,
      MentionCustomization,
      PageBreak,
      PageMarginsButton,
      Pagination,
      Paragraph,
      RemoveFormat,
      ReportBlockPromptWidgets,
      SlashCommand,
      SpecialCharacters,
      SpecialCharactersEssentials,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableOfContents,
      TableProperties,
      TableToolbar,
      Template,
      Underline,
    ],
    toolbar: [
      "undo",
      "redo",
      "|",
      "pageNavigation",
      "|",
      "heading",
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "underline",
      "fontColor",
      "fontBackgroundColor",
      "formatPainter",
      {
        icon: icons.pencil,
        label: "Highlight",
        items: [
          "highlight:yellowMarker",
          "highlight:greenMarker",
          "highlight:pinkMarker",
          "highlight:greenPen",
          "highlight:redPen",
          "removeHighlight",
        ],
      },
      {
        label: "More Formats",
        items: ["strikethrough", "superscript", "subscript"],
      },
      "|",
      {
        label: "AkitaBox",
        withText: true,
        icon: false,
        items: ["abxAssets", "abxInsights", "abxFiles", "abxReportBlockPrompt"],
      },
      "|",
      "tableOfContents",
      "insertTable",
      "uploadImage",
      "pageBreak",
      {
        label: "Insert More",
        items: ["specialCharacters", "link", "blockQuote", "horizontalLine"],
      },
      "|",
      "alignment",
      "outdent",
      "indent",
      "bulletedList",
      "numberedList",
      "removeFormat",
      {
        label: "Page Setup",
        withText: true,
        icon: false,
        items: isConfigurableHeaderAndFooterEnabled
          ? ["abxHeaderButton", "abxFooterButton", "abxPageMarginsButton"]
          : ["abxPageMarginsButton"],
      },
    ],
    licenseKey: CK_EDITOR_LICENSE_KEY,
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: ["default", 8, 9, 10, 10.5, 11, 12, 14, 18, 24, 30, 36],
    },
    list: {
      properties: {
        styles: true,
      },
    },
    link: {
      addTargetToExternalLinks: true,
    },
    autosave: {
      save: async (editor) => {
        const editorData = editor.data.get();
        try {
          const sanitizedContent = sanitizeXSS(editorData);
          await api.reportTemplateContent.update({
            reportTemplateId,
            organizationId,
            // send base64 encoded content
            // (WAF blocks url encoded content that has a table)
            reportTemplateContent: { content: encodeText(sanitizedContent) },
          });
        } catch (err) {
          simple(
            "Failed saving report content. Update the document to try again.",
            { severity: "error" }
          );
        }
      },
    },
    image: {
      styles: {
        options: [
          {
            icon: "",
            name: "assetImageContained",
            title: "asset-image-contained",
            className: "asset-image-contained",
            modelElements: ["imageInline", "imageBlock"],
          },
          // I dunno why but we have to re-create default image styles if we default a new one, above
          {
            name: "alignLeft",
            icon: icons.objectLeft,
            title: "Left aligned image",
            className: "image-style-align-left",
            modelElements: ["imageBlock", "imageInline"],
          },
          {
            name: "alignCenter",
            icon: icons.objectCenter,
            title: "Centered image",
            className: "image-style-align-center",
            modelElements: ["imageBlock"],
          },
          {
            name: "alignRight",
            icon: icons.objectRight,
            title: "Right aligned image",
            className: "image-style-align-right",
            modelElements: ["imageBlock", "imageInline"],
          },
        ],
      },
      toolbar: [
        "toggleImageCaption",
        "|",
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "resizeImage:original",
      ],
    },
    mention: {
      feeds: [
        {
          marker: "#",
          feed: getFeedItems,
          itemRenderer: customItemRenderer,
        },
      ],
    },
    abxInsights: {
      setShowInsightsDialog,
    },
    abxFiles: {
      setShowFilesDialog,
    },
    abxReportBlockPrompt: {
      setShowReportBlockPromptDialog,
    },
    abxUpload: {
      organizationId: organization._id,
    },
    abxPageMargins: {
      setShowPageMarginsDialog,
    },
    abxHeaderAndFooter: {
      setShowFooterDialog,
      setShowHeaderDialog,
    },
    table: {
      contentToolbar: [
        "toggleTableCaption",
        "tableRow",
        "tableColumn",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    pagination: {
      pageWidth: "21cm",
      // A4 is supposed to be 29.7, but everything works better with 31.1
      pageHeight: "31.1cm",

      // once we include the main.css as part of the exported stylesheets to
      // the exportPdf plugin, it removes all margin
      pageMargins: {
        top: "0mm",
        bottom: "0mm",
        right: "0mm",
        left: "0mm",
      },
    },
    cloudServices: {
      tokenUrl: () => {
        return new Promise((resolve, reject) => {
          (async () => {
            try {
              const res = await api.ckeditor.getToken();
              const token: string = res.data;
              resolve(token);
            } catch (error) {
              reject(error);
            }
          })();
        });
      },
    },
    htmlEmbed: {
      showPreviews: true,
    },
  });

  const loadDashboards = useCallback(
    async (sisenseApp: any) => {
      try {
        const { data } = await sisenseApp.$$http.get(
          `${INSIGHTS_ENV}/api/v1/dashboards`
        );
        setDashboards(data);
      } catch (error) {
        simple("Failed loading sisense dashboards", { severity: "error" });
      }
    },
    [simple]
  );

  // Sisense JS integration
  const loadInsights = useCallback(() => {
    const script = document.createElement("script");
    script.id = "sisense-embed-script";
    // sisense.js breaks all angular routing upon being loaded into the DOM
    // sisense.v1.js fixes this issue, but it breaks pivot tables
    // we will want to monitor updates to this and future scripts to improve functionality
    script.src = `${INSIGHTS_ENV}/js/sisense.v1.js`;
    script.onload = async function () {
      try {
        // connect with persist=true to persist updates to dashboard
        const sisenseApp = await window.Sisense?.connect(INSIGHTS_ENV, true);
        InsightsService.sisenseApp = sisenseApp;
        setSisenseApp(sisenseApp);
        loadDashboards(sisenseApp);
      } catch (error) {
        simple("Failed connecting to sisense", { severity: "error" });
      }
    };
    document.body.appendChild(script);
  }, [loadDashboards, simple]);

  // USE EFFECTS

  useEffect(() => {
    if (reportTemplateResponse?.data) {
      reportContextRef.current = reportTemplateResponse.data.context;
    }

    if (reportTemplateResponse) {
      let top = 12;
      let right = 12;
      let bottom = 12;
      let left = 12;
      let unit: "in" | "mm" | "cm" = "mm";

      const { content_margins } = reportTemplateResponse.data;
      if (
        content_margins.top !== undefined &&
        content_margins.top !== null &&
        !isNaN(content_margins.top)
      ) {
        top = content_margins.top;
      }

      if (
        content_margins.right !== undefined &&
        content_margins.right !== null &&
        !isNaN(content_margins.right)
      ) {
        right = content_margins.right;
      }

      if (
        content_margins.bottom !== undefined &&
        content_margins.bottom !== null &&
        !isNaN(content_margins.bottom)
      ) {
        bottom = content_margins.bottom;
      }

      if (
        content_margins.left !== undefined &&
        content_margins.left !== null &&
        !isNaN(content_margins.left)
      ) {
        left = content_margins.left;
      }

      if (
        typeof content_margins.unit === "string" &&
        ["in", "cm", "mm"].includes(content_margins.unit)
      ) {
        unit = content_margins.unit as "in" | "mm" | "cm";
      }

      setPageMargins({ top, right, bottom, left, unit });
    }
  }, [reportTemplateResponse]);

  useEffect(() => {
    if (editor && contentResponse) {
      const { content } = contentResponse.data;
      // loads and decodes existing content from current report
      if (content !== null) {
        const decodedContent = decodeText(content);
        setEditorData(decodedContent);
      }
    }
  }, [editor, contentResponse]);

  // setup insights once on page load
  useEffect(() => {
    const sisenseApp = InsightsService.sisenseApp;
    if (!sisenseApp) {
      loadInsights();
    } else {
      setSisenseApp(sisenseApp);
      loadDashboards(sisenseApp);
    }
  }, [loadInsights, loadDashboards]);

  const handleInsightsSelectionDialogClose = useCallback(() => {
    setShowInsightsDialog(false);
    setSelectedDashboard("");
  }, []);

  const handleTemplateSelect = (templateData: InsightsTemplateSelection[]) => {
    handleInsightsSelectionDialogClose();
    if (editor && templateData) {
      templateData.forEach((template) => {
        editor.execute("insertInsightsBlockPrompt", template);
      });
    }
  };

  const calcTemplateRowHeight = useCallback(() => {
    if (!contentRef || !toolbarRef) return "auto";
    const height = contentRef.offsetHeight - toolbarRef.offsetHeight - 1;
    return `${height}px`;
  }, [contentRef, toolbarRef]);

  if (!isEnabled || !reportBuilderPermissions.read) {
    return <Navigate to="/" />;
  }

  if (reportError || ContentError) {
    return <Navigate to="/report_builder" />;
  }

  return (
    <Box
      css={[
        CkEditorWrapperStyles.root,
        { gridTemplateRows: `auto ${calcTemplateRowHeight()}` },
      ]}
    >
      <div
        ref={(ref) => setToolbarRef(ref)}
        css={CkEditorWrapperStyles.toolbar}
      >
        <Box
          css={[
            CkEditorWrapperStyles.nameWrapper,
            {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            },
          ]}
        >
          <Typography variant="h6" css={CkEditorWrapperStyles.name}>
            <span title={reportTemplateResponse?.data.name} ref={reportNameRef}>
              {reportTemplateResponse?.data.name}
            </span>
          </Typography>
          <Button
            variant="contained"
            startIcon={<SubjectIcon />}
            onClick={() => setShowGenerateReportDialog(true)}
          >
            Generate Report
          </Button>
        </Box>
        {/* toolbar takes a bit of time to generate, show it after it does  */}
        {reportBuilderPermissions.update && editorToolbar && (
          <div ref={(ref) => ref?.appendChild(editorToolbar)}></div>
        )}
        {isLoadingContent && <LinearProgress />}
      </div>
      {reportTemplateResponse && showGenerateReportDialog && (
        <GenerateReportDialog
          reportTemplate={reportTemplateResponse.data}
          close={() => {
            setShowGenerateReportDialog(false);
          }}
          open={showGenerateReportDialog}
          maxWidth="lg"
          fullWidth={true}
          PaperProps={{
            sx: {
              height: "90%",
            },
          }}
        />
      )}
      {reportContextRef.current && (
        <ReportFooterDialog
          open={showFooterDialog}
          organizationId={organizationId}
          reportTemplateId={reportTemplateId}
          pageMargins={pageMargins}
          reportContext={reportContextRef.current}
          onClose={async (_content, _reason) => {
            setShowFooterDialog(false);
          }}
        />
      )}
      {reportContextRef.current && (
        <ReportHeaderDialog
          open={showHeaderDialog}
          organizationId={organizationId}
          reportTemplateId={reportTemplateId}
          pageMargins={pageMargins}
          reportContext={reportContextRef.current}
          onClose={async (_content, _reason) => {
            setShowHeaderDialog(false);
          }}
        />
      )}
      <ReportMarginsDialog
        key={Object.values(pageMargins).join("")}
        open={showPageMarginsDialog}
        margins={pageMargins}
        onSave={async (margins: PageMargins) => {
          if (
            reportTemplateResponse &&
            (margins.top !== pageMargins.top ||
              margins.right !== pageMargins.right ||
              margins.bottom !== pageMargins.bottom ||
              margins.left !== pageMargins.left ||
              margins.unit !== pageMargins.unit)
          ) {
            // save
            const updatedReportTemplate = await api.reportTemplates.updateById({
              organizationId,
              reportTemplateId,
              reportTemplate: { content_margins: margins },
            });
            updateReportTemplate(updatedReportTemplate, { revalidate: false });
          }
          setShowPageMarginsDialog(false);
        }}
      />
      {sisenseApp && dashboards && (
        <InsightsPromptSelectionDialog
          open={showInsightsDialog}
          appContext={sisenseApp}
          sisenseEnv={INSIGHTS_ENV}
          dashboards={dashboards}
          onClose={handleInsightsSelectionDialogClose}
          selectedDashboard={selectedDashboard}
          setSelectedDashboard={setSelectedDashboard}
          onTemplateSelect={handleTemplateSelect}
        ></InsightsPromptSelectionDialog>
      )}
      {showFilesDialog && (
        <AttachmentBuildingDialog
          organization={organization._id}
          open={true}
          onClose={(documents) => {
            if (documents.length && editor) {
              for (const { document } of documents) {
                const imageUrl = `<img src="${document.public_thumbnail_url_display}"/>`;
                const viewFragment = editor?.data.processor.toView(imageUrl);
                const modelFragment = editor?.data.toModel(viewFragment);
                editor?.model.insertContent(modelFragment);
              }
            }
            setShowFilesDialog(false);
          }}
        />
      )}
      <ReportBlockPromptDialog
        organizationId={organizationId}
        open={showReportBlockPromptDialog}
        fullWidth
        maxWidth="lg"
        handleClose={async (
          action,
          description,
          reportBlock,
          reportBlockFilters
        ) => {
          if (action === "insert" && editor) {
            // insert the report block prompt into the editor
            editor.execute("insertReportBlockPrompt", [
              description,
              reportBlock,
              reportBlockFilters,
            ]);
          }
          setShowReportBlockPromptDialog(false);
        }}
      />
      <div
        ref={(ref) => setDocumentOutlineRef(ref)}
        css={CkEditorWrapperStyles.documentOutline}
      ></div>
      {documentOutlineRef && (
        <div css={contentStyle.container}>
          <CKEditor<DecoupledEditor>
            disabled={!reportBuilderPermissions.update || isLoadingContent}
            editor={DecoupledEditor}
            data={editorData}
            config={{
              ...editorConfig,
              documentOutline: { container: documentOutlineRef },
            }}
            onReady={async (editor: DecoupledEditor) => {
              // make the editing window flex to fill the remaining space when editing
              // this works because our root element is a flex box
              setEditor(editor);
              // set the toolbar once the editor is ready so we can render it on the DOM
              setEditorToolbar(editor.ui.view.toolbar.element);
            }}
          />
        </div>
      )}
    </Box>
  );
};

const CkEditorWrapperStyles = stylesheet({
  root: () => ({
    display: "grid",
    gridTemplateColumns: "minmax(auto, 0.15fr) 1fr",
    backgroundColor: "var(--ck-color-base-foreground)",
    gridTemplateAreas: '"Toolbar Toolbar" "Sidebar Content"',
  }),
  toolbar: () => ({ gridArea: "Toolbar" }),
  documentOutline: (theme) => ({
    gridArea: "Sidebar",
    overflowY: "auto",
    padding: theme.spacing(6, 0, 0, 2),
  }),
  nameWrapper: (theme) => ({
    padding: theme.spacing(1, 2),
    backgroundColor: Color.White,
  }),
  name: () => ({
    maxWidth: "528px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
});
