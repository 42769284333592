import {
  CustomFieldAssociationResponse,
  CustomFieldDefinitionResponse,
} from "@akitabox/api-client";
import { api } from "../../api";
import { AbxMentionFeedItem } from "../../report-template/ReportTemplateEditor";

export const buildingFeedItems: AbxMentionFeedItem[] = [
  {
    id: "#Building.Name",
    field: "building.name",
  },
  {
    id: "#Building.Address",
    field: "building.address",
  },
  {
    id: "#Building.Year Built",
    field: "building.date_built",
  },
  {
    id: "#Building.Description",
    field: "building.description",
  },
  {
    id: "#Building.Website",
    field: "building.website",
  },
  {
    id: "#Building.Type",
    field: "building.type",
  },
  {
    id: "#Building.Year Purchased",
    field: "building.date_purchased",
  },
  {
    id: "#Building.ID",
    field: "building.building_id",
  },
  {
    id: "#Building.Contact Name",
    field: "building.emergency_contact_name",
  },
  {
    id: "#Building.Phone Number",
    field: "building.emergency_contact_phone",
  },
];

export const buildingRPIFCIFeedItems: AbxMentionFeedItem[] = [
  {
    id: "#Building.Estimated Current Replacement Value",
    field: "building.estimated_replacement_value",
  },
  {
    id: "#Building.Mission Criticality (1-100)",
    field: "building.mission_criticality",
  },

  {
    id: "#Building.Assessment Date",
    field: "building.assessment_date",
  },

  {
    id: "#Building.FCI Score",
    field: "building.fci_score", // this is not a real field on the schema, will be calculated on the server
  },

  {
    id: "#Building.Estimated Renewal Cost",
    field: "building.estimated_renewal_cost", // this is not a real field on the schema, will be calculated on the server
  },
];

export const getAllCustomFieldFeedItems = async (
  organizationId: string,
  scope: "building" | "organization"
) => {
  let hasMore = true;
  const limit = 1000;
  let skip = 0;

  const customFieldDefinitions: CustomFieldDefinitionResponse[] = [];

  while (hasMore) {
    const response = await api.customFieldDefinitions.get({
      organizationId,
      limit,
      skip,
    });

    customFieldDefinitions.push(...response.data);

    if (customFieldDefinitions.length < limit) {
      hasMore = false;
    } else {
      hasMore = true;
      skip += limit;
    }
  }

  if (!customFieldDefinitions.length) {
    return [];
  }

  // we need to filter out the custom fields that are not related to the building
  skip = 0;
  hasMore = true;
  const customFieldAssociations: CustomFieldAssociationResponse[] = [];

  while (hasMore) {
    const response = await api.customFieldAssociations.get({
      organizationId,
      customFieldDefinition: `$in,${customFieldDefinitions
        .map((i) => i._id)
        .join(",")}`,
      linkedEntityType: scope,
      limit,
      skip,
    });

    customFieldAssociations.push(...response.data);

    if (response.data.length < limit) {
      hasMore = false;
    } else {
      hasMore = true;
      skip += limit;
    }
  }

  if (!customFieldAssociations.length) {
    return [];
  }

  const customFieldFeedIems: AbxMentionFeedItem[] = [];

  for (const customFieldDefinition of customFieldDefinitions) {
    const customFieldAssociation = customFieldAssociations.find(
      (i) => i.custom_field_definition === customFieldDefinition._id
    );

    if (!customFieldAssociation) {
      continue;
    }

    if (scope === "building") {
      customFieldFeedIems.push({
        id: `#Building.${customFieldDefinition.name}`,
        field: `building.${customFieldDefinition.name}`,
      });
    } else {
      customFieldFeedIems.push({
        id: `#Organization.${customFieldDefinition.name}`,
        field: `organization.${customFieldDefinition.name}`,
      });
    }
  }

  return customFieldFeedIems;
};
