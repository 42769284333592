(function () {
  /**
   * @controller AssetLifeCycleDialogController
   *
   * @see AssetLifeCycleDialogService#show
   */
  angular
    .module("akitabox.ui.dialogs.asset.lifeCycle")
    .controller(
      "AssetLifeCycleDialogController",
      AssetLifeCycleDialogController
    );

  /* @ngInject */
  function AssetLifeCycleDialogController(
    $mdDialog,
    $q,
    $scope,
    $timeout,
    enums,
    AssetService,
    CancellableService,
    CatalogService,
    CostLineService,
    OrganizationService,
    ConfirmationDialog,
    CatalogItemService,
    ToastService,
    FeatureFlagService
  ) {
    // ===============
    // Properties
    // ===============
    this.QUANTITY_UNITS = Object.values(enums.QUANTITY_UNIT.DISPLAY_SHORT);

    this.organization = OrganizationService.getCurrent();

    /**
     * @property { Asset[] }
     * Provided in `locals` by the service.
     */
    this.assets;

    /**
     * @property {string|undefined} errorMessage holds the error messages generated from API calls
     */
    this.errorMessage = "";

    /**
     * @property {number} progress progress of the bulk action of assigning each asset to a cost line
     */
    this.progress = 0;

    /**
     * @property {string} lastAction helps us know what to retry, the save or delete
     */
    this.lastAction = "";

    /**
     * @type { boolean } Based on `Catalogs` feature flag
     */
    this.isCatalogsEnabled = FeatureFlagService.isEnabled("catalogs") || false;

    this.catalogOptions = [
      {
        key: "custom",
        value: "Custom",
        isCustom: true,
        model: {
          name: "Custom",
        },
      },
    ];
    this.rsMeansCatalogOptions = [
      {
        key: "fmr",
        value: "Facilities Maintenance and Repair",
        group: "RS Means",
        isRSMeans: true,
        model: {
          name: "Facilities Maintenance and Repair",
        },
      },
      {
        key: "cnc",
        value: "Commercial New Construction",
        group: "RS Means",
        isRSMeans: true,
        model: {
          name: "Commercial New Construction",
        },
      },
      {
        key: "fcr",
        value: "Facilities and Commercial Renovation",
        group: "RS Means",
        isRSMeans: true,
        model: {
          name: "Facilities and Commercial Renovation",
        },
      },
    ];

    this.showCustomForm = true;
    this.showCatalogsForm = false;
    this.showRSMeansForm = false;

    this.existingCostLine = null;

    this.selectedCatalogOption = null;

    this.installationYear = null;
    this.assessedYear = null;
    this.scheduledYear = null;
    this.yearsDeferred = null;

    this.suggestedAssessedYear = null;
    this.suggestedScheduledYear = null;

    this.catalog = null;
    this.catalogItem = null;
    this.costLineData = null;

    this.description = null;
    this.suggested_interval = null;
    this.quantity = 1;
    this.quantityUnit = "Ea";
    this.unitCost = 0;
    this.totalCost = 0;

    this.isLoading = true;
    this.isSaving = false;
    this.isSavingInstallationDate = false;
    this.isDeleting = false;
    this.isEditingInstallationDate = false;
    this.isEditingCost = false;
    this.isFetchingCatalogItems = false;
    this.isFormInvalid = true;
    this.isSelectingCatalogItem = false;
    this.isSelectingCostLine = false;
    this.isSectionInvalid = false;

    this.searchText = null;
    this.catalogItems = [];
    this.selectedCatalogItem = null;

    const _formatUnit = (enumKey, value, variant) => {
      if (!enumKey || !value) return "";

      let formattedValue = null;
      const enumObj = enums[enumKey];

      switch (variant) {
        case "long":
          formattedValue = enumObj?.DISPLAY_LONG[value];
          break;
        case "short":
        default:
          formattedValue = enumObj?.DISPLAY_SHORT[value];
          break;
      }

      return formattedValue ? formattedValue : value;
    };

    this.fetchCatalogItems = (skip, limit) => {
      const params = { skip, limit };
      if (this.searchText) {
        params.search = this.searchText;
      }

      this.isFetchingCatalogItems = true;

      return CatalogItemService.get(this.catalog.id, params)
        .then(({ data: catalogItems }) => {
          if (!Array.isArray(catalogItems)) return 0;
          const filteredCatalogItems = catalogItems.filter(
            // only catalog items with cost
            (catalogItem) => !!catalogItem.unit_cost_cents
          );
          this.catalogItems = this.catalogItems.concat(filteredCatalogItems);

          return catalogItems.length;
        })
        .catch(ToastService.showError)
        .finally(() => {
          this.isFetchingCatalogItems = false;
        });
    };

    this.onCatalogItemSelect = (index) => {
      const catalogItem = this.catalogItems[index];
      if (!catalogItem) return;
      $scope.$apply(() => {
        if (
          !this.selectedCatalogItem ||
          this.selectedCatalogItem.id !== catalogItem.id
        ) {
          this.selectedCatalogItem = catalogItem;
        } else {
          this.selectedCatalogItem = null;
        }
      });
    };

    this.onSearchChange = angular.debounce(({ value }) => {
      this.searchText = value;
      this.catalogItems = [];
      $scope.$broadcast("list:refresh");
    }, 500);

    this.openCatalogItemSelect = () => {
      this.isSelectingCatalogItem = true;
      this.selectedCatalogItem = null;
    };

    this.clearCatalogItem = ($event) => {
      $event.stopPropagation();
      this.isSelectingCatalogItem = false;
      this.catalogItem = null;
      this.description = null;
      this.unitCost = 0;
      this.checkFormValidity();
    };

    this.openCostLineSelect = ($event) => {
      this.clearCostLine($event);
      this.isSelectingCostLine = true;
    };

    this.clearCostLine = ($event) => {
      $event.stopPropagation();
      this.isSelectingCostLine = false;
      this.costLineData = null;
      this.description = null;
      this.unitCost = 0;
      this.checkFormValidity();
    };

    /**
     * pipeline to hold a series of cancellable async functions
     * mostly for our bulk work of assigning each asset within this dialog
     * to the selected cost line
     */
    const initPipeline = CancellableService.createPipeline("canceled");

    this.checkFormValidity = () => {
      this.isFormInvalid =
        !this.description || !this.quantity || !this.unitCost;
      if (this.organization.require_life_cycle_fields) {
        this.isFormInvalid =
          this.isFormInvalid || !this.assessedYear || !this.scheduledYear;
      }
      if (this.showCatalogsForm) {
        this.isFormInvalid = this.isFormInvalid || !this.catalogItem;
      } else if (this.showRSMeansForm) {
        this.isFormInvalid = this.isFormInvalid || !this.costLineData;
      }
    };

    this.setCatalog = (key) => {
      for (const option of this.catalogOptions) {
        if (option.key === key) {
          this.selectedCatalogOption = option;
          if (option.isCustom) {
            this.catalog = null;
          } else if (option.isRSMeans) {
            this.catalog = key;
          } else {
            this.catalog = option.model;
          }
          break;
        }
      }
    };

    this.setTotalCost = () => {
      if (this.quantity && this.unitCost) {
        this.totalCost = this.quantity * this.unitCost;
      } else {
        this.totalCost = 0;
      }
    };

    this.setYearsDeferred = () => {
      if (this.scheduledYear && this.assessedYear) {
        this.yearsDeferred = this.scheduledYear - this.assessedYear;
      } else {
        this.yearsDeferred = null;
      }
    };

    this.setSuggestedDueDates = () => {
      let frequency = null;
      if (this.catalogItem) {
        frequency = this.catalogItem.frequency;
      } else if (this.costLineData) {
        frequency = this.costLineData.frequency;
      }
      if (this.installationYear && frequency) {
        this.suggestedAssessedYear = this.installationYear + frequency;
        this.suggestedScheduledYear = this.installationYear + frequency;
      }
    };

    this.handleCatalogChange = (value) => {
      let catalogOption = null;
      for (const option of this.catalogOptions) {
        if (option.model === value.model) {
          catalogOption = option;
          break;
        }
      }
      this.selectedCatalogOption = catalogOption;
      if (catalogOption) {
        this.showCustomForm = !!catalogOption.isCustom;
        this.showRSMeansForm = !!catalogOption.isRSMeans;
        this.showCatalogsForm = !(this.showCustomForm || this.showRSMeansForm);
        if (catalogOption.isCustom) {
          this.showCustomForm = true;
          this.showRSMeansForm = false;
          this.showCatalogsForm = false;
          this.costLineData = null;
          this.catalog = null;
        } else if (catalogOption.isRSMeans) {
          this.showCustomForm = false;
          this.showRSMeansForm = true;
          this.showCatalogsForm = false;
          this.costLineData = null;
          this.catalog = catalogOption.key;
        } else {
          this.showCatalogsForm = false;
          this.showRSMeansForm = false;
          this.showCatalogsForm = true;
          this.costLineData = null;
          this.catalog = catalogOption.model;
        }
      } else {
        this.showCustomForm = false;
        this.showCatalogsForm = false;
        this.showRSMeansForm = false;
        this.catalog = null;
        this.costLineData = null;
      }
      this.checkFormValidity();
    };

    this.handleCatalogItemChange = (catalogItem) => {
      this.isSelectingCatalogItem = false;
      if (catalogItem) {
        this.catalogItem = catalogItem;
        this.description = catalogItem.name;
        this.quantityUnit =
          enums.QUANTITY_UNIT.DISPLAY_SHORT[catalogItem.quantity_unit];
        this.unitCost = catalogItem.unit_cost_cents / 100;
        this.suggested_interval = catalogItem.frequency;
        this.setTotalCost();
        this.setSuggestedDueDates();
        this.setYearsDeferred();
        if (this.isEditingCost) {
          if (!this.assessedYear) {
            this.assessedYear = this.suggestedAssessedYear;
          }
          if (!this.scheduledYear) {
            this.scheduledYear = this.suggestedScheduledYear;
          }
        }
        this.checkFormValidity();
      }
    };

    this.handleCostLineDataChange = (event) => {
      if (this.isSectionInvalid) return;
      this.isSelectingCostLine = false;
      if (event) {
        const costLineData = event.model;
        this.costLineData = costLineData;
        this.suggested_interval = costLineData.frequency;
        this.description = costLineData.description;
        this.quantityUnit =
          enums.QUANTITY_UNIT.DISPLAY_SHORT[costLineData.unit];
        this.unitCost = costLineData.costPerUnit;
        this.setTotalCost();
        this.setSuggestedDueDates();
        this.setYearsDeferred();
        if (this.isEditingCost) {
          if (!this.assessedYear) {
            this.assessedYear = this.suggestedAssessedYear;
          }
          if (!this.scheduledYear) {
            this.scheduledYear = this.suggestedScheduledYear;
          }
        }
        this.checkFormValidity();
      }
    };

    this.updateField = angular.debounce((event, fieldName) => {
      let value = event.value;
      switch (fieldName) {
        case "installation_date":
          this.installationYear = value;
          break;
        case "assessed_due_date":
          this.assessedYear = value;
          break;
        case "scheduled_due_date":
          this.scheduledYear = value;
          break;
        case "description":
          this.description = value;
          break;
        case "quantity":
          this.quantity = value;
          break;
        case "quantity_unit":
          this.quantityUnit = value;
          break;
        case "unit_cost":
          this.unitCost = value;
          break;
      }

      this.setYearsDeferred();
      this.setTotalCost();
      this.checkFormValidity();
    }, 500);

    this.getCatalogs = () => {
      return new Promise((resolve, reject) => {
        if (this.isCatalogsEnabled) {
          return CatalogService.getAll()
            .then(({ data: catalogs }) => {
              for (const catalog of catalogs) {
                this.catalogOptions.push({
                  key: catalog.id,
                  value: catalog.name,
                  group: "Catalogs",
                  model: catalog,
                });
              }
              if (this.organization.show_fca_rsmeans) {
                Array.prototype.push.apply(
                  this.catalogOptions,
                  this.rsMeansCatalogOptions
                );
              }
              resolve();
            })
            .catch(reject);
        }

        if (this.organization.show_fca_rsmeans) {
          Array.prototype.push.apply(
            this.catalogOptions,
            this.rsMeansCatalogOptions
          );
        }
        resolve();
      });
    };

    this.getExistingCatalogItem = (catalogId, catalogItemId) => {
      return CatalogItemService.getOneById(catalogId, catalogItemId).then(
        ({ data: catalogItem }) => {
          this.handleCatalogItemChange(catalogItem);
          this.setCatalog(catalogItem.catalog);
        }
      );
    };

    this.getExistingCostLine = (asset) => {
      return CostLineService.getOneByAsset(asset).then((costLine) => {
        if (costLine) {
          this.existingCostLine = costLine;
          if (costLine.assessed_due_date) {
            this.assessedYear = new Date(
              costLine.assessed_due_date
            ).getFullYear();
          }
          if (costLine.scheduled_due_date) {
            this.scheduledYear = new Date(
              costLine.scheduled_due_date
            ).getFullYear();
          }
          this.description = costLine.description;
          this.suggested_interval = costLine.suggested_interval;
          this.quantity = costLine.quantity;
          this.quantityUnit =
            enums.QUANTITY_UNIT.DISPLAY_SHORT[costLine.quantity_unit];
          this.unitCost = costLine.unit_cost;
          this.setYearsDeferred();
          this.setTotalCost();
          if (costLine.abx_catalog) {
            this.showCustomForm = false;
            this.showCatalogsForm = true;
            this.showRSMeansForm = false;
            return this.getExistingCatalogItem(
              costLine.abx_catalog,
              costLine.abx_catalog_item
            );
          } else if (costLine.catalog) {
            this.showCustomForm = false;
            this.showCatalogsForm = false;
            this.showRSMeansForm = true;
            this.costLineData = costLine;
            this.setCatalog(costLine.catalog);
          }
        } else {
          this.isEditingCost = true;
        }
      });
    };

    this.updateInstallationDate = (asset) => {
      const newDate = this.installationYear
        ? new Date(this.installationYear, 0, 2)
        : null;
      const assetDate = asset.installation_date
        ? new Date(asset.installation_date)
        : null;
      if (assetDate === null && newDate === null) {
        return $q.resolve();
      } else if (
        assetDate &&
        newDate &&
        assetDate.getUTCFullYear() === newDate.getUTCFullYear()
      ) {
        return $q.resolve();
      }
      return AssetService.updateById(asset.building, asset._id, {
        installation_date: newDate.getTime(),
      });
    };

    this.updateQuantity = (asset, quantity) => {
      return AssetService.updateQuantity(asset.building, asset._id, quantity);
    };

    this.deleteCostLine = (asset, costLine) => {
      return CostLineService.remove(asset.building, costLine._id);
    };

    this.createCostLine = (asset) => {
      let quantityUnit;
      for (const key in enums.QUANTITY_UNIT.DISPLAY_SHORT) {
        if (enums.QUANTITY_UNIT.DISPLAY_SHORT[key] === this.quantityUnit) {
          quantityUnit = key;
          break;
        }
      }

      const data = {
        asset: asset._id,
        description: this.description,
        quantity: this.quantity,
        quantity_unit: quantityUnit,
        assessed_due_date: null,
        scheduled_due_date: null,
        user_cost: this.unitCost,
      };

      if (this.assessedYear) {
        data.assessed_due_date = new Date(this.assessedYear, 0, 1).getTime();
      }
      if (this.scheduledYear) {
        data.scheduled_due_date = new Date(this.scheduledYear, 0, 1).getTime();
      }
      if (this.costLineData) {
        data.uniformat = this.costLineData.uniformat.toUpperCase();
        data.catalog = this.selectedCatalogOption.key;
        data.suggested_interval = this.costLineData.frequency;
      }

      return CostLineService.create(asset.building, data);
    };

    this.classifyAsset = (asset) => {
      const assessedDueDate = new Date(this.assessedYear, 0, 1);
      const scheduledDueDate = new Date(this.scheduledYear, 0, 1);

      return AssetService.classify(
        asset.building,
        asset._id,
        this.catalogItem,
        this.quantity,
        assessedDueDate.getTime(),
        scheduledDueDate.getTime()
      );
    };

    this.recreateCostLine = (asset, costLine) => {
      return this.deleteCostLine(asset, costLine).then(() => {
        return this.createCostLine(asset);
      });
    };

    this.updateCostLineDates = (asset, costLine) => {
      const assessedDueDate = this.assessedYear
        ? new Date(this.assessedYear, 0, 1)
        : null;
      const scheduledDueDate = this.scheduledYear
        ? new Date(this.scheduledYear, 0, 1)
        : null;
      const currentAssessedDueDate = costLine.assessed_due_date
        ? new Date(costLine.assessed_due_date)
        : null;
      const currentScheduledDueDate = costLine.scheduled_due_date
        ? new Date(costLine.scheduled_due_date)
        : null;

      const data = {};

      if (
        assessedDueDate &&
        (!currentAssessedDueDate ||
          currentAssessedDueDate.getUTCFullYear() !==
            assessedDueDate.getUTCFullYear())
      ) {
        data.assessed_due_date = assessedDueDate.getTime();
      }
      if (
        scheduledDueDate &&
        (!currentScheduledDueDate ||
          currentScheduledDueDate.getUTCFullYear() !==
            scheduledDueDate.getUTCFullYear())
      ) {
        data.scheduled_due_date = scheduledDueDate.getTime();
      }

      if (Object.keys(data).length === 0) return $q.resolve();

      return CostLineService.updateById(asset.building, costLine._id, data);
    };

    this.declassifyAsset = (asset) => {
      return AssetService.classify(asset.building, asset._id, null);
    };

    this.reclassifyAsset = (asset) => {
      return this.declassifyAsset(asset).then(() => {
        return this.classifyAsset(asset);
      });
    };

    this.setSectionInvalid = (isInvalid) => {
      this.isSectionInvalid = isInvalid;
    };

    this.$onInit = () => {
      $scope.$watch(
        "vm.searchText",
        angular.debounce(() => {
          $scope.$broadcast("list:refresh");
        }, 500)
      );
      this.selectedCatalogOption = this.catalogOptions[0];
      this.editingCost = false;
      return this.getCatalogs()
        .then(() => {
          const asset = this.assets[0];
          if (asset) {
            if (this.assets.length === 1) {
              if (asset.installation_date) {
                this.installationYear = new Date(
                  asset.installation_date
                ).getFullYear();
              }
              return this.getExistingCostLine(asset);
            } else {
              this.isEditingCost = true;
            }
          }
        })
        .finally(() => {
          $timeout(() => {
            this.isLoading = false;
          }, 0);
        });
    };

    this.toDollars = (cents) => {
      if (!cents) return 0;
      const centsNumber = Number(cents);
      if (Number.isNaN(centsNumber)) return 0;
      return centsNumber / 100;
    };

    this.toCurrency = (cents) => {
      const dollars = this.toDollars(cents);
      if (dollars === 0) return "";
      const { format } = new Intl.NumberFormat("en-US", {
        currency: "USD",
        style: "currency",
      });
      return format(dollars);
    };

    this.formatSizeUnit = (value, variant) => {
      return _formatUnit("SIZE_UNIT", value, variant);
    };

    this.formatQuantityUnit = (value, variant) => {
      return _formatUnit("QUANTITY_UNIT", value, variant);
    };

    /**
     * @method cancel
     * Cancel the dialog
     */
    this.cancel = () => {
      if (this.isSaving | this.isDeleting) {
        initPipeline.cancel();
      } else {
        $mdDialog.hide(this.costLineDeleted);
      }
    };

    this.reset = () => {
      this.showCustomForm = true;
      this.showCatalogsForm = false;
      this.showRSMeansForm = false;
      this.existingCostLine = null;
      this.catalog = null;
      this.catalogItem = null;
      this.costLineData = null;
      this.assessedYear = null;
      this.scheduledYear = null;
      this.description = null;
      this.unitCost = 0;
      this.setSuggestedDueDates();
      this.setYearsDeferred();
      this.setTotalCost();
      this.checkFormValidity();
      this.$onInit();
    };

    this.saveInstallationDate = () => {
      if (!this.assets.length) {
        return $q.resolve();
      }

      this.progress = 0;
      this.errorMessage = "";
      this.isSavingInstallationDate = true;
      this.lastAction = "saveInstallationDate";

      const fns = [];
      let count = 0;

      this.assets.forEach((asset) => {
        fns.push(() => {
          return this.updateInstallationDate(asset).then(() => {
            count++;
            this.progress = Math.floor((count / this.assets.length) * 100);
          });
        });
      });

      return initPipeline
        .switchTo(CancellableService.executeSeries(fns))
        .then(() => {
          this.isSavingInstallationDate = false;
          this.isEditingInstallationDate = false;
        })
        .catch((err) => {
          const remaining = this.assets.length - count;
          if (err !== "canceled") {
            this.errorMessage = `Error occured. ${remaining} assets could not be updated`;
            initPipeline.cancel(); // cancel remaining ones
          }
          // $mdDialog.hide() removes this from the DOM, so we can't put setSaving in a finally
          this.isSavingInstallationDate = false;
          this.isEditingInstallationDate = true;
        });
    };

    this.save = () => {
      if (!this.assets.length) {
        return $q.resolve();
      }

      this.progress = 0;
      this.errorMessage = "";
      this.isSaving = true;
      this.lastAction = "save";

      const fns = [];
      let count = 0;

      if (this.assets.length === 1) {
        const asset = this.assets[0];
        fns.push(() => {
          return CostLineService.getOneByAsset(asset)
            .then((costLine) => {
              if (this.catalogItem) {
                if (costLine) {
                  if (costLine.abx_catalog_item !== this.catalogItem.id) {
                    return this.reclassifyAsset(asset);
                  }
                  return this.updateCostLineDates(asset, costLine).then(() => {
                    if (costLine.quantity !== this.quantity) {
                      return this.updateQuantity(asset, this.quantity);
                    }
                  });
                }
                return this.classifyAsset(asset);
              } else if (costLine) {
                return this.recreateCostLine(asset, costLine);
              }
              return this.createCostLine(asset);
            })
            .then(() => {
              count++;
              this.progress = Math.floor((count / this.assets.length) * 100);
            });
        });
      } else {
        this.assets.forEach((asset) => {
          fns.push(() => {
            return this.declassifyAsset(asset)
              .then(() => {
                if (this.catalogItem) {
                  return this.classifyAsset(asset);
                } else {
                  return this.createCostLine(asset);
                }
              })
              .then(() => {
                count++;
                this.progress = Math.floor((count / this.assets.length) * 100);
              });
          });
        });
      }

      return initPipeline
        .switchTo(CancellableService.executeSeries(fns))
        .then(() => {
          this.isSaving = false;
          this.isEditingCost = false;
          this.isFormInvalid = false;
          $mdDialog.hide(true);
        })
        .catch((err) => {
          const remaining = this.assets.length - count;
          if (err !== "canceled") {
            this.errorMessage = `Error occured. ${remaining} assets could not be updated`;
            initPipeline.cancel(); // cancel remaining ones
          } else {
            this.isFormInvalid = false;
          }
          // $mdDialog.hide() removes this from the DOM, so we can't put setSaving in a finally
          this.isSaving = false;
          this.isEditingCost = true;
        });
    };

    this.delete = () => {
      ConfirmationDialog.show({
        title:
          this.assets.length > 1
            ? `Remove costs from all ${this.assets.length} assets?`
            : `Remove costs from ${this.assets[0].name}`,
        prompt:
          this.assets.length > 1
            ? `Are you sure you want remove the cost from each of these ${this.assets.length} assets?`
            : `Are you sure you want to remove ${this.assets[0].name}'s cost?`,
        isDestructive: true,
        confirmText: "Remove",
        onConfirm: () => {
          this.progress = 0;
          this.errorMessage = "";
          this.isDeleting = true;
          this.lastAction = "delete";
          this.costLineDeleted = true;

          let count = 0;
          const fns = [];

          this.assets.forEach((asset) => {
            fns.push(() => {
              return this.declassifyAsset(asset).then(() => {
                count++;
                this.progress = Math.floor((count / this.assets.length) * 100);
              });
            });
          });

          // We don't return the promise here becuase we don't want the dialog
          // to wait on all the deletes. We want to go back to the selection dialog
          // and be able to cancel during the delete if needed
          initPipeline
            .switchTo(CancellableService.executeSeries(fns))
            .then(() => {
              this.reset();
            })
            .catch((err) => {
              const remaining = this.assets.length - count;
              if (err !== "canceled") {
                // probably some api error, we need to alert and cancel any remaining requests
                this.errorMessage = `Error occured. Remaining ${remaining} assets could not be updated`;
                initPipeline.cancel();
              }
            })
            .finally(() => {
              this.isDeleting = false;
            });
        },
      });
    };

    this.retry = () => {
      if (this.lastAction === "delete") {
        this.delete();
      } else if (this.lastAction === "save") {
        this.save();
      } else {
        // just make the button and error go away
        this.errorMessage = "";
        this.isFormInvalid = false;
        this.isSaving = false;
        this.isDeleting = false;
      }
    };
  }
})();
