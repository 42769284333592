import {
  FunctionComponent,
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
  ElementRef,
} from "react";
import { renderToString } from "react-dom/server";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { EditorConfig, icons } from "@ckeditor/ckeditor5-core";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
} from "@ckeditor/ckeditor5-basic-styles";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Link } from "@ckeditor/ckeditor5-link";
import {
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
} from "@ckeditor/ckeditor5-table";
import {
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
} from "@ckeditor/ckeditor5-font";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { Indent, IndentBlock } from "@ckeditor/ckeditor5-indent";
import { List } from "@ckeditor/ckeditor5-list";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { RemoveFormat } from "@ckeditor/ckeditor5-remove-format";
import ImageUtils from "@ckeditor/ckeditor5-image/src/imageutils";
import {
  AutoImage,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
} from "@ckeditor/ckeditor5-image";
import {
  SpecialCharacters,
  SpecialCharactersEssentials,
} from "@ckeditor/ckeditor5-special-characters";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { Highlight } from "@ckeditor/ckeditor5-highlight";
import { PageBreak } from "@ckeditor/ckeditor5-page-break";
import { HorizontalLine } from "@ckeditor/ckeditor5-horizontal-line";
import { Pagination } from "@ckeditor/ckeditor5-pagination";
import { ExportPdf } from "@ckeditor/ckeditor5-export-pdf";
import { HtmlEmbed } from "@ckeditor/ckeditor5-html-embed";
import { Autosave } from "@ckeditor/ckeditor5-autosave";
import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
import { ImportWord } from "@ckeditor/ckeditor5-import-word";
import { ExportWord } from "@ckeditor/ckeditor5-export-word";
import { FormatPainter } from "@ckeditor/ckeditor5-format-painter";
import { Mention } from "@ckeditor/ckeditor5-mention";
import { SlashCommand } from "@ckeditor/ckeditor5-slash-command";
import { Template } from "@ckeditor/ckeditor5-template";
import DocumentOutline from "@ckeditor/ckeditor5-document-outline/src/documentoutline";
import TableOfContents from "@ckeditor/ckeditor5-document-outline/src/tableofcontents";

import { InsightsWidgetButton } from "./plugins/InsightsWidget.plugin";
import { AbxUploadAdapterPlugin } from "./plugins/AbxUploadAdapter";

import { stylesheet } from "../stylesheet";
import { useParams, Navigate, useRouteLoaderData } from "react-router";
import { DecoupledEditor } from "@ckeditor/ckeditor5-editor-decoupled";
import { Box, Typography, LinearProgress } from "@mui/material";
import {
  FileResponse,
  OrganizationResponse,
  ReportHeaderFooterResponse,
  UserResponse,
} from "@akitabox/api-client";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { api } from "../api";
import { ReportBlockSelectionDialog } from "../report-block-dialog/ReportBlockSelectionDialog";
import { getCKEditorKey } from "../../utils/getCKEditorKey";
import { sanitizeXSS } from "../../utils/xss";
import { encodeText } from "../../utils/encodeText";
import { decodeText } from "../../utils/decodeText";
import { Color } from "../colors";
import {
  ImageData,
  InsightsWidgetSelectionDialog,
  InsightsQueryData,
} from "./insights/InsightsWidgetSelectionDialog";
import { getInsightsEnv } from "../../utils/getInsightsEnv";
import {
  DashboardModel,
  FilterDimension,
  InsightsWidgetType,
} from "./insights/types";
import { base64AkitaboxFooterLogo } from "../../utils/getBase64FooterLogo";
import { useGlobalSnackbar } from "../consecutive-snackbar/GlobalSnackbar";
import InsightsService from "../services/InsightsService";

import "./ck-editor.css";
import { ExportPdfButton } from "./plugins/ExportPdf.plugin";
import { ExportWordButton } from "./plugins/ExportWord.plugin";
import { FilesWidgetButton } from "./plugins/FilesWidget.plugin";
import { AttachmentBuildingDialog } from "../attachment-building-dialog/AttachmentBuildingDialog";
import { useShellDOM } from "../hooks/useShellDOM";
import { ReportBlocksWidgetButton } from "./plugins/ReportBlocksWidget.plugin";
import { sanitize } from "dompurify";
import { SisenseWidget } from "../report-builder/insights/SisenseWidget";
import {
  Filter,
  createAttribute,
  createDimension,
  filterFactory as filters,
} from "@sisense/sdk-data";
import { toBlob } from "html-to-image";
import { createRoot } from "react-dom/client";
import axios, { AxiosResponse } from "axios";
import {
  PageMargins,
  ReportMarginsDialog,
} from "../report-margins-dialog/ReportMarginsDialog";
import { PageMarginsButton } from "../ck-editor/plugins/PageMarginsButton.plugin";
import { ExportWordConverterOptionsV2 } from "@ckeditor/ckeditor5-export-word/src/exportword";
import { ReportFooterDialog } from "../report-footer-dialog/ReportFooterDialog";
import { FooterButton } from "../ck-editor/plugins/FooterButton.plugin";
import { ButtonView } from "@ckeditor/ckeditor5-ui";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { ExecuteMultiQueryByWidgetIds } from "./insights/ExecuteMultiQueryByWidgetIds";
import { SisenseContextProvider } from "@sisense/sdk-ui";
import { ReportHeaderDialog } from "../report-header-dialog/ReportHeaderDialog";
import { HeaderButton } from "../ck-editor/plugins/HeaderButton.plugin";

const INSIGHTS_ENV = getInsightsEnv();
const CK_EDITOR_LICENSE_KEY = getCKEditorKey();

// Remove the long PURL querystring and only compare the base URLs
export function normalizePurlStrings(htmlString: string) {
  // This regex matches src attributes with presigned URLs and captures the base URL part
  const presignedUrlRegex = /src="(https:\/\/[^?"]+)[^"]*"/g;
  // Replace the entire src attribute value with the captured base URL
  return htmlString.replace(presignedUrlRegex, 'src="$1"');
}

export async function convertImgSrcToDataSrc(htmlSource: string) {
  let convertedHtml = htmlSource;

  for (const img of convertedHtml.match(/<img[^>]+>/g) || []) {
    const src = img.match(/src="([^"]+)"/);
    if (src) {
      const url = src[1];
      if (url.startsWith("data:image")) {
        continue;
      }
      const response = await fetch(url);
      if (!response.ok) {
        continue;
      }

      const imageType = response.headers.get("content-type");
      const buffer = await response.arrayBuffer();
      const bytes = new Uint8Array(buffer);
      let binary = "";
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const b64 = btoa(binary);
      convertedHtml = convertedHtml.replace(
        url,
        `data:${imageType};base64,${b64}`
      );
    }
  }

  return convertedHtml;
}

export const ReportBuilderEditor: FunctionComponent = () => {
  const { contentRef } = useShellDOM();
  const { simple } = useGlobalSnackbar();
  const { report: reportId } = useParams() as { report: string };
  const { organization, user, flags } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
    user: UserResponse;
    flags: { [key: string]: boolean };
  };
  const organizationId = organization._id;

  const { report_builder } = user.permission_group;
  const reportBuilderPermissions = useMemo(
    () => ({ read: report_builder["read"], update: report_builder["update"] }),
    [report_builder]
  );

  const isReportBuilderEnabled = flags["report_builder"];
  const isConfigurableHeaderAndFooterEnabled =
    flags["configurable_header_and_footer"];

  const {
    data: reportContentResponse,
    error: reportContentError,
    isValidating: isLoadingReportContent,
  } = makeUseServiceCall(api.reportContent.getById)({
    organizationId,
    reportId,
  });

  const {
    data: reportResponse,
    error: reportError,
    mutate: updateReport,
  } = makeUseServiceCall(api.reports.getById)({
    organizationId,
    reportId,
  });

  const reportNameRef = useRef<ElementRef<"span">>(null);
  const [toolbarRef, setToolbarRef] = useState<ElementRef<"div"> | null>(null);
  const [documentOutlineRef, setDocumentOutlineRef] =
    useState<ElementRef<"div"> | null>(null);

  const [sisenseApp, setSisenseApp] = useState<any>(null);
  const [dashboards, setDashboards] = useState<DashboardModel[]>();
  const [selectedDashboard, setSelectedDashboard] = useState<string>("");
  const [showInsightsDialog, setShowInsightsDialog] = useState<boolean>(false);
  const [showFilesDialog, setShowFilesDialog] = useState<boolean>(false);

  // margins
  const [showPageMarginsDialog, setShowPageMarginsDialog] =
    useState<boolean>(false);
  const [pageMargins, setPageMargins] = useState<PageMargins>({
    top: 12,
    right: 12,
    bottom: 12,
    left: 12,
    unit: "mm",
  });

  const STATUS_MESSAGES = {
    LOADING: "Loading...",
    DONE_LOADING: "Done loading.",
    SAVING: "Saving...",
    NO_CHANGES: "No changes to save.",
    DONE_SAVING: "Changes saved!",
  };

  const [statusMessage, setStatusMessage] = useState("");

  // header and footer
  const [showFooterDialog, setShowFooterDialog] = useState<boolean>(false);
  const [showHeaderDialog, setShowHeaderDialog] = useState<boolean>(false);

  const contentStyle = useMemo(() => {
    const padding = `${pageMargins.top}${pageMargins.unit} ${pageMargins.right}${pageMargins.unit} ${pageMargins.bottom}${pageMargins.unit} ${pageMargins.left}${pageMargins.unit}`;

    return stylesheet({
      container: (theme) => ({
        gridArea: "Content",
        overflowY: "auto",
        padding: theme.spacing(4),
        ".ck.ck-content.ck-editor__editable": {
          padding,
        },
        ".ck-editor__editable_inline": {
          /* Set the dimensions of the "page". */
          width: "216mm",
          minHeight: "297mm",
          /* Keep the "page" off the boundaries of the container. */
          padding: "20mm 12mm",
          border: "1px hsl( 0,0%,82.7% ) solid",
          borderRadius: "var(--ck-border-radius)",
          backgroundColor: "white",
          boxSizing: "border-box",
          /* The "page" should cast a slight shadow (3D illusion). */
          boxShadow: "0 0 5px hsla( 0,0%,0%,.1 )",
          /* Center the "page". */
          margin: "0 auto",
          /* Force all images in ck-editor to conform to the width of the page, this helps mostly with exporting to pdf */
          ".image.ck-widget img": {
            maxWidth: "191mm",
            height: "auto",
          },
          ".raw-html-embed__content-wrapper": {
            resize: "both",
          },
          /* Workaround to contain asset images within the same aspect ratio */
          ".asset-image-contained img": {
            aspectRatio: "4 / 3",
            objectFit: "contain",
          },
        },
      }),
    });
  }, [pageMargins]);

  const [insightsTemplateMatches, setInsightsTemplateMatches] =
    useState<RegExpMatchArray | null>(null);
  const [
    isLoadingInsightsScreenshotsFromTemplate,
    setIsLoadingInsightsScreenshotsFromTemplate,
  ] = useState<boolean>(false);
  const [showReportBlockSelectorDialog, setShowReportBlockSelectorDialog] =
    useState<boolean>(false);
  const [editor, setEditor] = useState<DecoupledEditor | null>(null);
  const [editorToolbar, setEditorToolbar] = useState<HTMLElement | null>(null);
  const [editorData, setEditorData] = useState<string>("");
  const [editorDataToReplace, setEditorDataToReplace] = useState<string>("");
  const [insightsWidgetsInserted, setInsightsWidgetsInserted] =
    useState<boolean>(false);
  const lastSavedReportContentRef = useRef<string | null>("");
  const initialSavePerformedRef = useRef<boolean>(false);

  const generateBlob = async (divNode: HTMLDivElement) => {
    const blob = await toBlob(divNode, {
      cacheBust: true,
      skipFonts: false,
    });

    return blob;
  };

  const getFooterCSS = useCallback(() => {
    const fontSize = "10.25pt";

    const FOOTER_CSS = `
        
      figure {
        margin: 0 auto;
        padding: 0;
        display: inline-block;
      }

      .footer {
        width: 100%;
        box-sizing: border-box;
        font-family: "Open Sans", sans-serif;
        color: rgba(0, 0, 0, .87);
        overflow-wrap: break-word;
        font-size: ${fontSize};
      }

      .branding-logo {
        box-sizing: border-box;
      }

      .abx-logo {
        height: 20px;
        text-align: center;
        box-sizing: border-box;
      }

      .abx-logo img {
        height: 20px;
        width: auto;
      }

      .abx-page-numbers {
        text-align: right;
      }
      
      img {
        max-width: 100%;
        height: auto;
      }
    `;

    return FOOTER_CSS;
  }, []);

  const [editorConfig] = useState<EditorConfig>({
    plugins: [
      AbxUploadAdapterPlugin,
      Alignment,
      FilesWidgetButton,
      ReportBlocksWidgetButton,
      Autoformat,
      Autosave,
      AutoImage,
      Bold,
      BlockQuote,
      CloudServices,
      DocumentOutline,
      Essentials,
      ExportPdf,
      ExportPdfButton,
      ExportWord,
      ExportWordButton,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      FooterButton,
      FormatPainter,
      HeaderButton,
      Heading,
      Highlight,
      HorizontalLine,
      HtmlEmbed,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      ImageUtils,
      ImportWord,
      Indent,
      IndentBlock,
      InsightsWidgetButton,
      Italic,
      Link,
      List,
      Mention,
      PageBreak,
      Pagination,
      PageMarginsButton,
      Paragraph,
      RemoveFormat,
      SlashCommand,
      SpecialCharacters,
      SpecialCharactersEssentials,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableOfContents,
      TableProperties,
      TableToolbar,
      Template,
      Underline,
    ],
    toolbar: [
      "abxExportPdf",
      "abxExportWord",
      "importWord",
      "|",
      "undo",
      "redo",
      "|",
      "pageNavigation",
      "|",
      "heading",
      "fontFamily",
      "fontSize",
      "bold",
      "italic",
      "underline",
      "fontColor",
      "fontBackgroundColor",
      "formatPainter",
      {
        icon: icons.pencil,
        label: "Highlight",
        items: [
          "highlight:yellowMarker",
          "highlight:greenMarker",
          "highlight:pinkMarker",
          "highlight:greenPen",
          "highlight:redPen",
          "removeHighlight",
        ],
      },
      {
        label: "More Formats",
        items: ["strikethrough", "superscript", "subscript"],
      },
      "|",
      {
        label: "AkitaBox",
        withText: true,
        icon: false,
        items: ["abxAssets", "abxInsights", "abxFiles", "abxReportBlocks"],
      },
      "|",
      "tableOfContents",
      "insertTable",
      "uploadImage",
      "pageBreak",
      {
        label: "Insert More",
        items: ["specialCharacters", "link", "blockQuote", "horizontalLine"],
      },
      "|",
      "alignment",
      "outdent",
      "indent",
      "bulletedList",
      "numberedList",
      "removeFormat",
      {
        label: "Page Setup",
        withText: true,
        icon: false,
        items: isConfigurableHeaderAndFooterEnabled
          ? ["abxHeaderButton", "abxFooterButton", "abxPageMarginsButton"]
          : ["abxPageMarginsButton"],
      },
    ],
    licenseKey: CK_EDITOR_LICENSE_KEY,
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: ["default", 8, 9, 10, 10.5, 11, 12, 14, 18, 24, 30, 36],
    },
    list: {
      properties: {
        styles: true,
      },
    },
    link: {
      addTargetToExternalLinks: true,
    },
    autosave: {
      save: async (editor) => {
        const currentData = editor.data.get();
        setStatusMessage(STATUS_MESSAGES.SAVING);

        // Normalize PURL strings to compare them to avoid unnecessary saves
        const normalizedCurrentData = normalizePurlStrings(currentData);
        const normalizedLastSavedData = lastSavedReportContentRef.current
          ? normalizePurlStrings(lastSavedReportContentRef.current)
          : null;

        if (
          normalizedLastSavedData &&
          normalizedCurrentData === normalizedLastSavedData &&
          initialSavePerformedRef.current
        ) {
          return;
        }

        lastSavedReportContentRef.current = currentData;

        try {
          const sanitizedContent = sanitizeXSS(currentData);
          await api.reportContent.update({
            reportId,
            organizationId,
            // send base64 encoded content
            // (WAF blocks url encoded content that has a table)
            reportContent: { content: encodeText(sanitizedContent) },
          });
          initialSavePerformedRef.current = true;
          setStatusMessage(STATUS_MESSAGES.DONE_SAVING);
        } catch (err) {
          simple(
            "Failed saving report content. Update the document to try again.",
            { severity: "error" }
          );
          setStatusMessage(STATUS_MESSAGES.DONE_SAVING);
        }
      },
    },
    image: {
      insert: {
        type: "auto",
      },
      styles: {
        options: [
          {
            icon: "",
            name: "assetImageContained",
            title: "asset-image-contained",
            className: "asset-image-contained",
            modelElements: ["imageInline", "imageBlock"],
          },
          // I dunno why but we have to re-create default image styles if we default a new one, above
          {
            name: "alignLeft",
            icon: icons.objectLeft,
            title: "Left aligned image",
            className: "image-style-align-left",
            modelElements: ["imageBlock", "imageInline"],
          },
          {
            name: "alignCenter",
            icon: icons.objectCenter,
            title: "Centered image",
            className: "image-style-align-center",
            modelElements: ["imageBlock"],
          },
          {
            name: "alignRight",
            icon: icons.objectRight,
            title: "Right aligned image",
            className: "image-style-align-right",
            modelElements: ["imageBlock", "imageInline"],
          },
        ],
      },
      toolbar: [
        "toggleImageCaption",
        "|",
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "resizeImage:original",
      ],
    },
    abxInsights: {
      setShowInsightsDialog,
    },
    abxFiles: {
      setShowFilesDialog,
    },
    abxReportBlocks: {
      setShowReportBlockSelectorDialog,
    },
    abxUpload: {
      organizationId: organization._id,
    },
    abxPageMargins: {
      setShowPageMarginsDialog,
    },
    abxHeaderAndFooter: {
      setShowFooterDialog,
      setShowHeaderDialog,
    },
    table: {
      contentToolbar: [
        "toggleTableCaption",
        "tableRow",
        "tableColumn",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    // this needs to correlate with exportPdf config, both are set to A4 paper
    pagination: {
      pageWidth: "21cm",
      // A4 is supposed to be 29.7, but everything works better with 31.1
      pageHeight: "31.1cm",

      // once we include the main.css as part of the exported stylesheets to
      // the exportPdf plugin, it removes all margin
      pageMargins: {
        top: "0mm",
        bottom: "0mm",
        right: "0mm",
        left: "0mm",
      },
    },
    cloudServices: {
      tokenUrl: () => {
        return new Promise((resolve, reject) => {
          (async () => {
            try {
              const res = await api.ckeditor.getToken();
              const token: string = res.data;
              resolve(token);
            } catch (error) {
              reject(error);
            }
          })();
        });
      },
    },
    // this needs to correlate with pagination config, both are set to A4 paper
    exportPdf: {
      stylesheets: [
        "https://fonts.googleapis.com/css?family=Montserrat:400,500,700",
        "https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic",
        "https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap",
        "EDITOR_STYLES",
        "/static/ck-editor.css",
      ],
      fileName: () => {
        if (reportNameRef.current) {
          const { textContent: reportName } = reportNameRef.current;
          return reportName ? `${reportName}.pdf` : "";
        }
        return "";
      },
      converterOptions: {
        format: "A4",
        // once we include the main.css as part of the exported stylesheets to
        // the exportPdf plugin, it removes all margin, these don't matter
        margin_top: `${pageMargins.top}${pageMargins.unit}`,
        margin_bottom: `${pageMargins.bottom}${pageMargins.unit}`,
        margin_right: `${pageMargins.right}${pageMargins.unit}`,
        margin_left: `${pageMargins.left}${pageMargins.unit}`,
        page_orientation: "portrait",
        footer_html: `<div class="footer"><img src="${base64AkitaboxFooterLogo}"/></div>`,
        header_and_footer_css: `
        .footer {
            display: flex;
            flex-direction: row-reverse;
            margin-right: 20px;
          }
        
          .footer img {
            aspect-radio: 16/9;
            object-fit: cover;
            max-height: 32px;
          }`,
      },
    },
    importWord: {
      formatting: {
        resets: "none",
        defaults: "none",
        styles: "inline",
        comments: "full",
      },
    },
    exportWord: {
      version: 2,
      stylesheets: [
        "https://fonts.googleapis.com/css?family=Montserrat:400,500,700",
        "https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic",
        "https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap",
        "EDITOR_STYLES",
        "/static/ck-editor-export-word.css",
      ],
      fileName: () => {
        if (reportNameRef.current) {
          const { textContent: reportName } = reportNameRef.current;
          return reportName ? `${reportName}.docx` : "";
        }
        return "";
      },
      converterOptions: {
        document: {
          orientation: "portrait",
          size: "A4",
          margins: {
            top: `${pageMargins.top}${pageMargins.unit}`,
            bottom: `${pageMargins.bottom}${pageMargins.unit}`,
            right: `${pageMargins.right}${pageMargins.unit}`,
            left: `${pageMargins.left}${pageMargins.unit}`,
          },
        },
        footers: {
          default: {
            html: `<div class="footer"><img src="${base64AkitaboxFooterLogo}"/></div>`,
            css: `
            .footer {
              display: flex;
              flex-direction: row-reverse;
              margin-right: 20px;
            }
          
            .footer img {
              aspect-radio: 16/9;
              object-fit: cover;
              max-height: 32px;
            }`,
          },
        },
        auto_pagination: true,
      } as ExportWordConverterOptionsV2,
    },
    htmlEmbed: {
      showPreviews: true,
    },
  });

  const findInsightsTemplates = (content: string) => {
    const insightsPromptRegexp =
      /<table data-id=[^>]* class="abx-insights-block-prompt[^"]*"[^>]*>([\s\S]*?)<\/table>/g;
    return content.match(insightsPromptRegexp);
  };

  const buildFilters = async (
    filterMap: Record<string, string[]>
  ): Promise<Filter[]> => {
    try {
      const qFilters: Filter[] = [];

      Object.entries(filterMap).forEach(([key, values]) => {
        const dim = `[${key}]`;
        const columnName = key.split(".")[1];
        const filterDim = createDimension({
          name: "Dimension",
          FilterDim: createAttribute({
            name: columnName,
            expression: dim,
          }),
        }) as FilterDimension;
        const filterDimensionName =
          columnName === "name" || columnName === "type"
            ? dim.replace(/\./g, "_").replace(/^\[|\]$/g, "")
            : columnName;
        const member = filters.members(filterDim[filterDimensionName], values);
        qFilters.push(member);
      });

      return qFilters;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    async function init() {
      setStatusMessage(STATUS_MESSAGES.LOADING);
      // logic to pull in footer content
      // TODO also fetch header content here
      try {
        // disable the export button while we fetch the footer content
        if (editor) {
          editor.ui.view.toolbar.items.forEach((item) => {
            const button = item as ButtonView;
            if (button.label === "Export Word") {
              button.isEnabled = false;
            }
          });
        }

        let reportFooter: ReportHeaderFooterResponse | undefined = undefined;
        let reportHeader: ReportHeaderFooterResponse | undefined = undefined;
        // we dont specify a type to get both header and footer
        const { data } = await api.reportHeadersAndFooters.getByOrganization({
          organizationId,
          report: reportId,
        });

        for (const headerFooter of data) {
          // don't fetch for header or footer if we've already fetched it
          if (reportFooter && headerFooter.type === "footer") {
            continue;
          } else if (reportHeader && headerFooter.type === "header") {
            continue;
          }

          if (headerFooter.type === "footer") {
            reportFooter = headerFooter;
          } else if (headerFooter.type === "header") {
            reportHeader = headerFooter;
          }

          if (reportFooter) {
            const { show_page_numbers } = reportFooter;
            const { data: footerContent } =
              await api.reportHeadersAndFooters.getContent({
                organizationId,
                reportHeaderFooterId: reportFooter._id,
              });
            if (footerContent && editor) {
              const decodedContent = await convertImgSrcToDataSrc(
                decodeText(footerContent.content)
              );
              const footerHTML = `
                <div class="footer">
                  <div class="branding-logo">${decodedContent}</div>
                  <div class="abx-logo"><img src="${base64AkitaboxFooterLogo}" /></div>       
                  ${
                    show_page_numbers
                      ? `<div class="abx-page-numbers">Page <span class="pageNumber"></span></div>`
                      : ""
                  }      
                </div>`;

              editor.config.set(
                "exportWord.converterOptions.footers.default.html",
                footerHTML
              );
              editor.config.set(
                "exportWord.converterOptions.footers.default.css",
                getFooterCSS()
              );
            }
          } else if (headerFooter) {
            const { data: headerContent } =
              await api.reportHeadersAndFooters.getContent({
                organizationId,
                reportHeaderFooterId: headerFooter._id,
              });
            if (headerContent && editor) {
              const decodedContent = await convertImgSrcToDataSrc(
                decodeText(headerContent.content)
              );
              const footerHTML = `<div class='header'>${decodedContent}</div>`;

              editor.config.set(
                "exportWord.converterOptions.headers.default.html",
                footerHTML
              );
              editor.config.set(
                "exportWord.converterOptions.headers.default.css",
                getFooterCSS()
              );
            }
          }
        }
      } catch (err) {
        simple("Failed loading footer content", { severity: "error" });
      }

      if (editor) {
        // reenable export buftton
        editor.ui.view.toolbar.items.forEach((item) => {
          const button = item as ButtonView;
          if (button.label === "Export Word") {
            button.isEnabled = true;
          }
        });
      }
    }

    if (isConfigurableHeaderAndFooterEnabled) {
      init();
    }
  }, [
    editor,
    getFooterCSS,
    isConfigurableHeaderAndFooterEnabled,
    organizationId,
    pageMargins.bottom,
    pageMargins.unit,
    reportId,
    simple,
  ]);

  useEffect(() => {
    if (editor && reportContentResponse) {
      setStatusMessage(STATUS_MESSAGES.LOADING);
      const { content } = reportContentResponse.data;
      // loads and decodes existing content from current report
      if (content !== null) {
        const decodedContent = decodeText(content);
        const matches = findInsightsTemplates(decodedContent);

        if (matches) {
          setIsLoadingInsightsScreenshotsFromTemplate(true);
          setEditorDataToReplace(decodedContent);
          setInsightsTemplateMatches(matches);
        } else {
          setEditorData(decodedContent);
        }
      }
    }

    if (reportResponse) {
      let top = 12;
      let right = 12;
      let bottom = 12;
      let left = 12;
      let unit: "in" | "mm" | "cm" = "mm";

      const { content_margins } = reportResponse.data;
      if (
        content_margins.top !== undefined &&
        content_margins.top !== null &&
        !isNaN(content_margins.top)
      ) {
        top = content_margins.top;
      }

      if (
        content_margins.right !== undefined &&
        content_margins.right !== null &&
        !isNaN(content_margins.right)
      ) {
        right = content_margins.right;
      }

      if (
        content_margins.bottom !== undefined &&
        content_margins.bottom !== null &&
        !isNaN(content_margins.bottom)
      ) {
        bottom = content_margins.bottom;
      }

      if (
        content_margins.left !== undefined &&
        content_margins.left !== null &&
        !isNaN(content_margins.left)
      ) {
        left = content_margins.left;
      }

      if (
        typeof content_margins.unit === "string" &&
        ["in", "cm", "mm"].includes(content_margins.unit)
      ) {
        unit = content_margins.unit as "in" | "mm" | "cm";
      }

      setPageMargins({ top, right, bottom, left, unit });
      if (editor) {
        editor.config.set(
          "exportPdf.converterOptions.margin_top",
          `${top}${unit}`
        );
        editor.config.set(
          "exportPdf.converterOptions.margin_bottom",
          `${bottom}${unit}`
        );
        editor.config.set(
          "exportPdf.converterOptions.margin_right",
          `${right}${unit}`
        );
        editor.config.set(
          "exportPdf.converterOptions.margin_left",
          `${left}${unit}`
        );
        editor.config.set(
          "exportWord.converterOptions.document.margins.top",
          `${top}${unit}`
        );
        editor.config.set(
          "exportWord.converterOptions.document.margins.bottom",
          `${bottom}${unit}`
        );
        editor.config.set(
          "exportWord.converterOptions.document.margins.right",
          `${right}${unit}`
        );
        editor.config.set(
          "exportWord.converterOptions.document.margins.left",
          `${left}${unit}`
        );
      }
    }
  }, [
    editor,
    reportResponse,
    reportContentResponse,
    organizationId,
    reportId,
    simple,
  ]);

  useEffect(() => {
    if (editor && editorData)
      lastSavedReportContentRef.current = editor?.data.get();
  }, [editorData, editor]);

  const loadDashboards = useCallback(
    async (sisenseApp: any) => {
      try {
        const { data } = await sisenseApp.$$http.get(
          `${INSIGHTS_ENV}/api/v1/dashboards`
        );
        setDashboards(data);
      } catch (error) {
        simple("Failed loading sisense dashboards", { severity: "error" });
      }
    },
    [simple]
  );

  const uploadAndFetchPurl = useCallback(
    async (file: File): Promise<string> => {
      try {
        const response: AxiosResponse<FileResponse> =
          await api.files.fileCreate({
            organization: organizationId,
            file: {
              name: file.name,
            },
          });

        // PUT file to returned upload PURL
        const data = new FormData();
        data.append("file", file, file.name);

        const { _id, upload_url } = response.data;
        await axios.put(upload_url as string, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        const completeUploadResponse: AxiosResponse<FileResponse> =
          await api.files.completeUpload({
            organization: organizationId,
            file: _id,
            fileAction: { action: "completeUpload" },
          });

        if (!completeUploadResponse.data.public_thumbnail_url_display) {
          return Promise.reject(new Error("Error generating insights image"));
        }

        return Promise.resolve(
          completeUploadResponse.data.public_thumbnail_url_display
        );
      } catch (err) {
        return Promise.reject(
          new Error("Cannot upload insights file attachment")
        );
      }
    },
    [organizationId]
  );

  // Sisense JS integration
  const loadInsights = useCallback(() => {
    const script = document.createElement("script");
    script.id = "sisense-embed-script";
    // sisense.js breaks all angular routing upon being loaded into the DOM
    // sisense.v1.js fixes this issue, but it breaks pivot tables
    // we will want to monitor updates to this and future scripts to improve functionality
    script.src = `${INSIGHTS_ENV}/js/sisense.v1.js`;
    script.onload = async function () {
      try {
        // connect with persist=true to persist updates to dashboard
        const sisenseApp = await window.Sisense?.connect(INSIGHTS_ENV, true);
        InsightsService.sisenseApp = sisenseApp;
        setSisenseApp(sisenseApp);
        loadDashboards(sisenseApp);
      } catch (error) {
        simple("Failed connecting to sisense", { severity: "error" });
      }
    };
    document.body.appendChild(script);
  }, [loadDashboards, simple]);

  // setup insights once on page load
  useEffect(() => {
    const sisenseApp = InsightsService.sisenseApp;
    if (!sisenseApp) {
      loadInsights();
    } else {
      setSisenseApp(sisenseApp);
      loadDashboards(sisenseApp);
    }
  }, [loadInsights, loadDashboards]);

  useEffect(() => {
    let renderComplete = false;
    let isRendering = false;
    const renderStuff = async () => {
      if (
        !editor ||
        !insightsTemplateMatches ||
        insightsWidgetsInserted ||
        isRendering
      ) {
        setStatusMessage(STATUS_MESSAGES.DONE_LOADING);
        return;
      }

      isRendering = true;
      const parser = new DOMParser();

      const widgetScreenshotMap: Record<
        string,
        { widgetTitle: string; blob: Blob | null; error: Error | undefined }
      > = {};
      const insightsQueryHTMLMap: Record<string, EmotionJSX.Element> = {};

      const screenshotPromises = [];
      const queryPromises = [];

      for (const match of insightsTemplateMatches) {
        const tempDiv = document.createElement("div");
        tempDiv.style.position = "absolute";
        tempDiv.style.left = "-9999px"; // Render off-screen
        tempDiv.style.width = "1000px";
        tempDiv.style.height = "800px";
        document.body.appendChild(tempDiv);

        const doc = parser.parseFromString(match, "text/html");

        const dashboardHTML = doc.querySelector(
          ".abx-insights-block-prompt__dashboard-id-value"
        );

        const widgetHTML = doc.querySelector(
          ".abx-insights-block-prompt__widget-id-value"
        );

        const widgetTypeHTML = doc.querySelector(
          ".abx-insights-block-prompt__widget-type-value"
        );

        const heightHTML = doc.querySelector(
          ".abx-insights-block-prompt__height-value"
        );

        const widthHTML = doc.querySelector(
          ".abx-insights-block-prompt__width-value"
        );

        const descriptionHTML = doc.querySelector(
          ".abx-insights-block-prompt__description-value"
        );

        const filterHTML = doc.querySelector(
          ".abx-insights-block-prompt__filter-value"
        );

        const showIndicatorTitleHTML = doc.querySelector(
          ".abx-insights-block-prompt__show-title-value"
        );

        const uniqueElementIdHTML = doc.querySelector("[data-id]");

        const dashboardOid = dashboardHTML?.textContent;
        const widgetOid = widgetHTML?.textContent;
        const widgetType = widgetTypeHTML?.textContent;
        const widgetTitle = descriptionHTML?.textContent;
        const height = heightHTML?.textContent;
        const width = widthHTML?.textContent;
        const showIndicatorTitle =
          showIndicatorTitleHTML?.textContent === "true";

        if (!dashboardOid || !widgetOid || !uniqueElementIdHTML) return;

        // The filter build does not depend on single or all buildings template
        // If the template is single building, the prompt will contain the building name and organization name inserted API side + any other filters
        // If the template is all buildings, the prompt will contain any filters added by the user
        let widgetFilters: Filter[] = [];
        const filtersParsedFromPrompt: { [key: string]: string[] } = {};

        const filterText = filterHTML?.textContent;
        if (!filterText) return;

        const filterRegex = /\[([^\]]+)\]=([^;]+);?/g;
        let filterMatch;

        while ((filterMatch = filterRegex.exec(filterText)) !== null) {
          let values = [];
          const key = filterMatch[1].trim();
          const value = filterMatch[2].trim();

          // Names can have commas in them, so we delimit with "||" instead of ","
          if (key === "dim_buildings.building_name" || value.includes("||")) {
            values = values = value
              .split("||")
              .map((s) => s.trim().replace(/\s*\[.*$/, ""));
          } else {
            // Handle legacy comma delimited values
            values = value
              .split(",")
              .map((s) => s.trim().replace(/\s*\[.*$/, ""));
          }

          filtersParsedFromPrompt[key] = values;
        }

        const organizationName =
          filtersParsedFromPrompt["dim_buildings.organization_name"]?.[0];
        const buildingName =
          filtersParsedFromPrompt["dim_buildings.building_name"]?.[0];

        // cannot request a widget without these filters. We don't know what buildings the user has access to
        if (!organizationName || !buildingName) return;

        widgetFilters = await buildFilters(filtersParsedFromPrompt);

        const uniqueElementId = uniqueElementIdHTML.getAttribute("data-id");

        if (!uniqueElementId) return;

        const root = createRoot(tempDiv);

        if (widgetType === InsightsWidgetType.CHART) {
          screenshotPromises.push(
            new Promise<void>((resolve) => {
              root.render(
                <SisenseWidget
                  sisenseEnv={INSIGHTS_ENV}
                  widgetOid={widgetOid}
                  dashboardOid={dashboardOid}
                  filters={widgetFilters || []}
                  height={height ? parseInt(height) : 0}
                  width={width ? parseInt(width) : 0}
                  onAfterRender={async (
                    domElement: HTMLDivElement | undefined,
                    error: Error | undefined
                  ) => {
                    if (error || !domElement) {
                      widgetScreenshotMap[uniqueElementId] = {
                        widgetTitle: widgetTitle || "",
                        blob: null,
                        error,
                      };
                      return resolve();
                    }

                    const blob = await generateBlob(domElement);
                    if (!blob) {
                      return resolve();
                    }
                    widgetScreenshotMap[uniqueElementId] = {
                      widgetTitle: widgetTitle || "",
                      blob,
                      error: undefined,
                    };
                    document.body.removeChild(tempDiv);
                    return resolve();
                  }}
                />
              );
            })
          );
        } else {
          queryPromises.push(
            new Promise<void>((resolve) => {
              root.render(
                <SisenseContextProvider url={INSIGHTS_ENV} ssoEnabled={true}>
                  <ExecuteMultiQueryByWidgetIds
                    queryWidgetSelections={[
                      {
                        widgetOid: widgetOid,
                        widgetTitle: widgetTitle || "No Title Provided",
                        widgetType:
                          widgetType === InsightsWidgetType.INDICATOR
                            ? InsightsWidgetType.INDICATOR
                            : InsightsWidgetType.TABLE,
                      },
                    ]}
                    dashboardOid={dashboardOid}
                    confirmSelections={(queryData?: InsightsQueryData[]) => {
                      if (!queryData) return resolve();
                      for (const data of queryData) {
                        const dataDisplayHTML =
                          widgetType === InsightsWidgetType.INDICATOR
                            ? buildIndicatorBlock(data, showIndicatorTitle)
                            : buildHTMLTable(data, showIndicatorTitle);
                        insightsQueryHTMLMap[uniqueElementId] = dataDisplayHTML;
                        document.body.removeChild(tempDiv);
                        return resolve();
                      }
                    }}
                    selectedDashboard={dashboardOid}
                    externalFilters={widgetFilters || []}
                  ></ExecuteMultiQueryByWidgetIds>
                </SisenseContextProvider>
              );
            })
          );
        }
      }

      await Promise.all(screenshotPromises);
      await Promise.all(queryPromises);

      let editorData = editorDataToReplace;
      for (const [uniqueElementId, htmlElement] of Object.entries(
        insightsQueryHTMLMap
      )) {
        const insightsPromptRegexpString = `[^>]*"${uniqueElementId}"[^>]*>([\\s\\S]*?)<\\/table>`;
        const insightsPromptRegexp = new RegExp(
          insightsPromptRegexpString,
          "g"
        );
        const matchToReplace = editorData.match(insightsPromptRegexp);
        if (!matchToReplace) continue;
        editorData = editorData.replace(
          matchToReplace[0],
          renderToString(htmlElement)
        );
      }
      for (const [
        uniqueElementId,
        { widgetTitle, blob, error },
      ] of Object.entries(widgetScreenshotMap)) {
        let displayHTML = "";
        if (!blob || error) {
          displayHTML = `<div className="insights-widget-error">
            <span style="font-weight: bold;">Error rendering widget "${widgetTitle}" with error: </span>
            <span style="color: red;">${
              error ? error.message : "unknown"
            }</span>
          </div>`;
        } else {
          const file = new File([blob], `${widgetTitle}.png`, {
            type: "image/png",
          });
          const purl = await uploadAndFetchPurl(file);
          displayHTML = `<img className="insights-widget-img" src="${purl}"/>`;
        }

        const insightsPromptRegexpString = `[^>]*"${uniqueElementId}"[^>]*>([\\s\\S]*?)<\\/table>`;
        const insightsPromptRegexp = new RegExp(
          insightsPromptRegexpString,
          "g"
        );
        const matchToReplace = editorData.match(insightsPromptRegexp);
        if (!matchToReplace) continue;
        editorData = editorData.replace(matchToReplace[0], displayHTML);
      }

      isRendering = false;
      renderComplete = true;

      setInsightsTemplateMatches(null);
      setEditorDataToReplace("");
      setInsightsWidgetsInserted(true);
      setEditorData(editorData);
      setIsLoadingInsightsScreenshotsFromTemplate(false);
      setStatusMessage(STATUS_MESSAGES.DONE_LOADING);
    };

    if (!renderComplete && sisenseApp) {
      renderStuff();
    }
  }, [
    editor,
    editorDataToReplace,
    insightsTemplateMatches,
    sisenseApp,
    insightsWidgetsInserted,
    uploadAndFetchPurl,
  ]);

  const handleInsightsSelectionDialogClose = useCallback(() => {
    setShowInsightsDialog(false);
    setSelectedDashboard("");
  }, []);

  const handleWidgetMultiselect = useCallback(
    (imageData: ImageData[], queryData: InsightsQueryData[] | undefined) => {
      handleInsightsSelectionDialogClose();
      if (editor) {
        imageData.forEach((data: ImageData) => {
          const viewFragment = editor.data.processor.toView(data.url);
          const modelFragment = editor.data.toModel(viewFragment);
          const titleFragment = editor.data.processor.toView(data.widgetTitle);
          const titleModelFragment = editor.data.toModel(titleFragment);
          editor.model.insertContent(titleModelFragment);
          editor.model.insertContent(modelFragment);
        });

        if (queryData && queryData.length) {
          queryData.forEach((queryData: InsightsQueryData) => {
            const dataDisplayHTML =
              queryData.widgetType === "indicator"
                ? buildIndicatorBlock(queryData, true)
                : buildHTMLTable(queryData, true);
            if (dataDisplayHTML) {
              const viewFragment = editor.data.processor.toView(
                renderToString(dataDisplayHTML)
              );
              const newlineFragment = editor.data.processor.toView(
                renderToString(<br />)
              );

              const documentFragment = editor.data.toModel(viewFragment);
              editor.model.insertContent(
                documentFragment,
                editor.model.document.selection.getFirstPosition()
              );

              const newlineModelFragment = editor.data.toModel(newlineFragment);
              editor.model.insertContent(
                newlineModelFragment,
                editor.model.document.selection.getLastPosition()
              );
            }
          });
        }
      }
    },
    [handleInsightsSelectionDialogClose, editor]
  );

  const buildHTMLTable = (queryData: InsightsQueryData, showTitle: boolean) => {
    const { columns, rows, widgetTitle, error } = queryData;
    if (error) {
      return (
        <div>
          <div className="insights-widget-error">
            <span style={{ fontWeight: "bold" }}>
              Error rendering widget &quot;{widgetTitle}&quot; with error:{" "}
            </span>
            <span style={{ color: "red" }}>
              {error ? error.message : "unknown"}
            </span>
          </div>
        </div>
      );
    }
    const thElements = columns.map((col, idx) => <th key={idx}>{col.name}</th>);
    const trElements = rows.map((row, rowIdx) => (
      <tr key={rowIdx}>
        {row.map((rowData: any, cellIdx: any) => {
          // URL data returned as raw HTML from Sisense. Sanitizing for extra caution as we do in other areas of report builder.
          if (
            typeof rowData.data === "string" &&
            rowData.data.startsWith("<a href=")
          ) {
            return (
              <td
                key={cellIdx}
                dangerouslySetInnerHTML={{
                  __html: sanitize(rowData.data),
                }}
              />
            );
          } else {
            return <td key={cellIdx}>{rowData.data}</td>;
          }
        })}
      </tr>
    ));
    return (
      <div>
        {showTitle && <h3>{widgetTitle || "No title"}</h3>}
        {rows.length ? (
          <table>
            <thead>
              <tr>{thElements}</tr>
            </thead>
            <tbody>{trElements}</tbody>
          </table>
        ) : (
          <div>No data for selected filters</div>
        )}
      </div>
    );
  };

  const buildIndicatorBlock = (
    queryData: InsightsQueryData,
    showTitle: boolean
  ) => {
    const {
      rows,
      widgetTitle,
      symbol = "",
      color = "",
      decimalFormat = "auto",
      error,
    } = queryData;

    if (error) {
      return (
        <div>
          <div className="insights-widget-error">
            <span style={{ fontWeight: "bold" }}>
              Error rendering widget &quot;{widgetTitle}&quot; with error:{" "}
            </span>
            <span style={{ color: "red" }}>
              {error ? error.message : "unknown"}
            </span>
          </div>
        </div>
      );
    }

    const indicatorValue = rows[0][0]?.data;

    const isValidNumber = !isNaN(Number(indicatorValue));

    // Format the indicator value
    let formattedValue = indicatorValue;
    if (isValidNumber) {
      let value = Number(indicatorValue);
      if (symbol === "%") {
        // Convert decimal to percentage
        value *= 100;
      }
      if (isNaN(Number(decimalFormat)) || decimalFormat === "auto") {
        if (value < 1 && value > 0) {
          // Keep the value as is if it's between 0 and 1
          formattedValue = value.toString();
        } else if (value.toString().split(".")[1]?.length > 2) {
          // If the value has more than 2 decimal places, round to 2 decimal places
          formattedValue = value.toFixed(2).toLocaleString();
        } else {
          formattedValue = Math.round(value).toLocaleString();
        }
      } else if (decimalFormat === 0) {
        formattedValue = Math.round(value).toLocaleString();
      } else {
        const format = Number(decimalFormat);
        formattedValue = value
          .toFixed(format)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
    }

    let formattedIndicatorValue;
    if (symbol === "$" && isValidNumber) {
      formattedIndicatorValue = `${symbol}${formattedValue}`;
    } else if (symbol === "%" && isValidNumber) {
      formattedIndicatorValue = `${formattedValue}${symbol}`;
    } else {
      formattedIndicatorValue = formattedValue;
    }

    const style = color ? { color } : {};

    const labelData = showTitle ? (
      <label>
        <b>{widgetTitle || "No title"}:</b>{" "}
        <span style={style}>{formattedIndicatorValue}</span>
      </label>
    ) : (
      <span style={style}>{formattedIndicatorValue}</span>
    );

    return <div>{labelData}</div>;
  };

  const calcTemplateRowHeight = useCallback(() => {
    if (!contentRef || !toolbarRef) return "auto";
    const height = contentRef.offsetHeight - toolbarRef.offsetHeight - 1;
    return `${height}px`;
  }, [contentRef, toolbarRef]);

  if (!isReportBuilderEnabled || !reportBuilderPermissions.read) {
    return <Navigate to="/" />;
  }

  if (reportError || reportContentError) {
    return <Navigate to="/report_builder" />;
  }

  const processChunks = async (blockContents: string[]) => {
    for (const reportBlock of blockContents) {
      if (editor) {
        const decodedBlock = decodeText(reportBlock);
        const viewFragment = editor.data.processor.toView(decodedBlock);
        const documentFragment = editor.data.toModel(viewFragment);
        // Move the selection to the end of the document
        const doc = editor.model.document.getRoot()?.root;
        if (doc) {
          const endPosition = editor.model.createPositionAt(doc, "end");
          editor.model.change((writer) => {
            writer.setSelection(endPosition);
          });

          // Insert the content at the end position
          editor.model.insertContent(documentFragment, endPosition);
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 250));
    }
  };

  return (
    <Box
      css={[
        CkEditorWrapperStyles.root,
        { gridTemplateRows: `auto ${calcTemplateRowHeight()}` },
      ]}
    >
      <div
        ref={(ref) => setToolbarRef(ref)}
        css={CkEditorWrapperStyles.toolbar}
      >
        <Box css={CkEditorWrapperStyles.reportNameWrapper}>
          <Typography variant="h6" css={CkEditorWrapperStyles.reportName}>
            <span title={reportResponse?.data.name} ref={reportNameRef}>
              {reportResponse?.data.name}
            </span>
          </Typography>
          <Typography id="editor-status" variant="caption">
            <strong>Status:</strong> {statusMessage}
          </Typography>
        </Box>
        {/* toolbar takes a bit of time to generate, show it after it does  */}
        {reportBuilderPermissions.update && editorToolbar && (
          <div ref={(ref) => ref?.appendChild(editorToolbar)}></div>
        )}
        {(isLoadingReportContent ||
          isLoadingInsightsScreenshotsFromTemplate) && <LinearProgress />}
      </div>
      {sisenseApp && dashboards && (
        <InsightsWidgetSelectionDialog
          open={showInsightsDialog}
          appContext={sisenseApp}
          sisenseEnv={INSIGHTS_ENV}
          dashboards={dashboards}
          onClose={handleInsightsSelectionDialogClose}
          selectedDashboard={selectedDashboard}
          setSelectedDashboard={setSelectedDashboard}
          onWidgetMultiselect={handleWidgetMultiselect}
          generatePurlDisplay={uploadAndFetchPurl}
        ></InsightsWidgetSelectionDialog>
      )}
      <ReportMarginsDialog
        key={Object.values(pageMargins).join("")}
        open={showPageMarginsDialog}
        margins={pageMargins}
        onSave={async (margins: PageMargins) => {
          if (
            reportResponse &&
            (margins.top !== pageMargins.top ||
              margins.right !== pageMargins.right ||
              margins.bottom !== pageMargins.bottom ||
              margins.left !== pageMargins.left ||
              margins.unit !== pageMargins.unit)
          ) {
            // save
            const updatedReport = await api.reports.update({
              organizationId,
              reportId,
              report: { content_margins: margins },
            });
            updateReport(updatedReport, { revalidate: false });
          }
          setShowPageMarginsDialog(false);
        }}
      />
      {/* key is necessary becasue ck-editor instance inside dialog needs to be re-created on each open/close */}
      <ReportFooterDialog
        open={showFooterDialog}
        organizationId={organizationId}
        reportId={reportId}
        pageMargins={pageMargins}
        reportContext=""
        onClose={async (
          {
            content,
            showPageNumbers,
          }: { content: string; showPageNumbers: boolean },
          _reason
        ) => {
          setShowFooterDialog(false);

          // we need to find ALL images and conver them to data urls
          const contentWithImageDataSrc = await convertImgSrcToDataSrc(content);
          const footerHTML = `
            <div class='footer'>
              <div class='branding-logo'>${contentWithImageDataSrc}</div>
              <div class='abx-logo'><img src='${base64AkitaboxFooterLogo}' /></div>
              ${
                showPageNumbers
                  ? `<div class="abx-page-numbers">Page <span class="pageNumber"></span></div>`
                  : ``
              }
            </div>`;

          if (editor) {
            editor.config.set(
              "exportWord.converterOptions.footers.default.html",
              footerHTML
            );
          }
        }}
      />
      <ReportHeaderDialog
        open={showHeaderDialog}
        organizationId={organizationId}
        reportId={reportId}
        pageMargins={pageMargins}
        reportContext=""
        onClose={async ({ content }: { content: string }, _reason) => {
          setShowHeaderDialog(false);

          // we need to find ALL images and conver them to data urls
          const contentWithImageDataSrc = await convertImgSrcToDataSrc(content);
          const headerHTML = `<div class='header'>${contentWithImageDataSrc}</div>`;

          if (editor) {
            editor.config.set(
              "exportWord.converterOptions.headers.default.html",
              headerHTML
            );
          }
        }}
      />
      <ReportBlockSelectionDialog
        open={showReportBlockSelectorDialog}
        onClose={async (result) => {
          if (result.action === "cancel") {
            setShowReportBlockSelectorDialog(false);
            return;
          }

          setShowReportBlockSelectorDialog(false);
        }}
        processChunks={processChunks}
        organizationId={organizationId}
      />
      {showFilesDialog && (
        <AttachmentBuildingDialog
          organization={organization._id}
          open={true}
          onClose={(documents) => {
            if (documents.length && editor) {
              for (const { document } of documents) {
                const imageUrl = `<img src="${document.public_thumbnail_url_display}"/>`;
                const viewFragment = editor?.data.processor.toView(imageUrl);
                const modelFragment = editor?.data.toModel(viewFragment);
                editor?.model.insertContent(modelFragment);
              }
            }
            setShowFilesDialog(false);
          }}
        />
      )}
      <div
        ref={(ref) => setDocumentOutlineRef(ref)}
        css={CkEditorWrapperStyles.documentOutline}
      ></div>
      {documentOutlineRef && (
        <div css={contentStyle.container}>
          <CKEditor<DecoupledEditor>
            disabled={
              !reportBuilderPermissions.update ||
              isLoadingReportContent ||
              isLoadingInsightsScreenshotsFromTemplate
            }
            editor={DecoupledEditor}
            data={editorData}
            config={{
              ...editorConfig,
              documentOutline: { container: documentOutlineRef },
            }}
            onReady={(editor: DecoupledEditor) => {
              // make the editing window flex to fill the remaining space when editing
              // this works because our root element is a flex box
              setEditor(editor);
              // set the toolbar once the editor is ready so we can render it on the DOM
              setEditorToolbar(editor.ui.view.toolbar.element);
              // set our config for our custon config button
              editor.config.set("abxExportPdf", { organizationId, reportId });
              editor.config.set("abxExportWord", { organizationId, reportId });
            }}
          />
        </div>
      )}
    </Box>
  );
};

const CkEditorWrapperStyles = stylesheet({
  root: () => ({
    display: "grid",
    gridTemplateColumns: "minmax(auto, 0.15fr) 1fr",
    backgroundColor: "var(--ck-color-base-foreground)",
    gridTemplateAreas: '"Toolbar Toolbar" "Sidebar Content"',
  }),
  toolbar: () => ({ gridArea: "Toolbar" }),
  documentOutline: (theme) => ({
    gridArea: "Sidebar",
    overflowY: "auto",
    padding: theme.spacing(6, 0, 0, 2),
  }),
  reportNameWrapper: (theme) => ({
    padding: theme.spacing(1, 2),
    backgroundColor: Color.White,
  }),
  reportName: () => ({
    maxWidth: "528px",
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  }),
});
