(function () {
  angular
    .module("akitabox.core.services.env", [
      "ngCookies",
      "akitabox.constants",
      "akitabox.core.constants",
      "akitabox.core.lib.sniffr",
    ])
    .factory("EnvService", EnvService);

  /* @ngInject */
  function EnvService(
    // Angular
    $location,
    $cookies,
    // Services
    Sniffr,
    // Constants
    apps,
    MOBILE_PREFERENCE_COOKIE,
    PORTAL_ID,
    PORTAL_SECRET
  ) {
    var service = {
      // Constants
      BETA: { DOMAIN: "beta-abx", ENV: "beta" },
      TEMP: { DOMAIN: "test-abx", ENV: "temp" },
      LOCAL: { DOMAIN: "local-abx", ENV: "local" },
      PRODUCTION: { DOMAIN: "akitabox", ENV: "production" },
      TEST: { DOMAIN: "server", ENV: "test" },
      // Functions
      getApiUrl: getApiUrl,
      getBaseUrl: getBaseUrl,
      getCurrentBaseUrl: getCurrentBaseUrl,
      getOrgPortalBaseUrl: getOrgPortalBaseUrl,
      getSiteAdminUrl: getSiteAdminUrl,
      getCatalogsRoute: getCatalogsRoute,
      getPlanViewUrl: getPlanViewUrl,
      getAccountSettingsUrl: getAccountSettingsUrl,
      getCustomerAccountSettingsUrl: getCustomerAccountSettingsUrl,
      getOrgSettingsUrl: getOrgSettingsUrl,
      getCmUrl: getCmUrl,
      getBrowser: getBrowser,
      getEnvName: getEnvName,
      getEnv: getEnv,
      getDomain: getDomain,
      getSubdomain: getSubdomain,
      getOS: getOS,
      getPortalHeaders: getPortalHeaders,
      extractDomainParts: extractDomainParts,
      isPortalDomain: isPortalDomain,
      setMobilePref: setMobilePref,
    };

    var EXTRACT_SUBDOMAIN_REGEX = new RegExp(
      "(.*)(?:.(" +
        service.BETA.DOMAIN +
        "|" +
        service.TEMP.DOMAIN +
        "|" +
        service.LOCAL.DOMAIN +
        "|" +
        service.PRODUCTION.DOMAIN +
        ").com)"
    );

    var subdomain;
    var domain;
    var domainSuffix;
    var environment;
    var apiBase;
    var desktopBase;
    var mobileBase;
    var orgPortalBase;
    var siteAdminBase;
    var accountSettingsBase;
    var customerAccountSettingsBase;
    var orgSettingsBase;
    var cmBase;
    var catalogsBase;
    var portalHeaders = {
      "x-client-id": PORTAL_ID,
    };
    var os = null;
    var browser = {
      name: null,
      version: null,
      versionString: null,
      mobile: false,
      outdated: false,
      isAndroid: false,
      isIphone: false,
      isOldIE: false,
    };

    init();

    return service;

    // ------------------------
    //   Public Functions
    // ------------------------

    function getApiUrl(route) {
      return route ? apiBase + formatRoute(route) : apiBase;
    }

    /**
     *
     * @param {String}    route       Url path
     * @param {Boolean}   mobile      Boolean indicating if user wants the mobile route
     * @param {String}    subdomain   Url subdomain
     *
     * @returns {String}
     */
    function getBaseUrl(route, mobile, subdomain) {
      var base = mobile ? mobileBase : desktopBase;
      base = injectSubdomain(base, subdomain);
      return route ? base + formatRoute(route) : base;
    }
    /**
     * Get the current base URL. As opposed to `getBaseUrl`, this function
     * includes any subdomain the user is currently at
     * @return {string}
     */
    function getCurrentBaseUrl() {
      // Use location.host vs. $location.host() to include port
      return $location.protocol() + "://" + location.host;
    }

    function getOrgPortalBaseUrl(route, subdomain) {
      var base = injectSubdomain(orgPortalBase, subdomain);
      return route ? base + formatRoute(route) : base;
    }

    function getSiteAdminUrl(route) {
      return route ? siteAdminBase + formatRoute(route) : siteAdminBase;
    }

    function getCatalogsRoute(route) {
      return route ? catalogsBase + formatRoute(route) : catalogsBase;
    }

    function getPlanViewUrl(route, query) {
      var result;
      var planViewBase = desktopBase + "/plan";
      planViewBase = injectSubdomain(planViewBase, getSubdomain());
      result = route ? planViewBase + formatRoute(route) : planViewBase;
      result += query ? query : "";

      return result;
    }

    function getAccountSettingsUrl(route) {
      return route
        ? accountSettingsBase + formatRoute(route)
        : accountSettingsBase;
    }

    function getCustomerAccountSettingsUrl(route, accountId) {
      return route
        ? customerAccountSettingsBase +
            formatRoute(route) +
            formatRoute(accountId)
        : customerAccountSettingsBase;
    }

    function getOrgSettingsUrl(route, subdomain) {
      var base = injectSubdomain(orgSettingsBase, subdomain);
      return route ? base + formatRoute(route) : base;
    }

    function getCmUrl(route, subdomain) {
      var base = injectSubdomain(cmBase, subdomain);
      return route ? base + formatRoute(route) : base;
    }

    function getBrowser() {
      return browser;
    }

    function getEnvName() {
      return environment.ENV; // returns lowercase string name of env (local, beta, production)
    }

    function getEnv() {
      return environment; // returns object that includes name and domain of env
    }

    function getDomain() {
      var port = $location.port();
      return domain + ".com" + (port ? ":" + $location.port() : "");
    }

    function getSubdomain() {
      return subdomain;
    }

    function getOS() {
      return os;
    }

    function getPortalHeaders() {
      return portalHeaders;
    }

    function isPortalDomain() {
      return subdomain === "portal";
    }

    function setMobilePref(preference) {
      if (!preference) preference = "mobile";
      $cookies.remove(MOBILE_PREFERENCE_COOKIE);
      $cookies.put(MOBILE_PREFERENCE_COOKIE, preference, {
        domain: [domain, domainSuffix].join("."),
      });
    }

    function extractDomainParts(url) {
      var urlParts = url.split("/");
      var match = EXTRACT_SUBDOMAIN_REGEX.exec(url);
      var subdomain = match ? match[1] : "";
      var domain;
      if (subdomain.length) {
        domain = urlParts[0].replace(subdomain + ".", "");
      } else {
        domain = urlParts[0];
      }
      var domainParts = domain.split(".");

      var port = null;
      var suffix = domainParts[1];
      if (suffix && suffix.indexOf(":")) {
        port = suffix.split(":")[1];
      }

      return {
        subdomain: subdomain,
        domain: domainParts[0],
        suffix: domainParts[1],
        port: port,
      };
    }

    // ------------------------
    //   Private Functions
    // ------------------------

    function formatRoute(route) {
      // make sure the route begins with a /
      return route.charAt(0) === "/" ? route : "/" + route;
    }

    function injectSubdomain(url, subdomain) {
      if (subdomain) {
        return url.replace("://", "://" + subdomain + ".");
      }
      return url;
    }

    function init() {
      // Environment and base routes
      var domainParts = extractDomainParts($location.host());

      subdomain = domainParts.subdomain;
      domain = domainParts.domain;
      domainSuffix = domainParts.suffix;

      switch (domain) {
        case service.LOCAL.DOMAIN:
          environment = service.LOCAL;
          // remove explicit port from api base URL
          apiBase = apps.CORE.ENV.LOCAL.URL_BASE.replace(/:\d+$/, "");
          desktopBase = apps.DESKTOP.ENV.LOCAL.URL_BASE;
          mobileBase = apps.MOBILE.ENV.LOCAL.URL_BASE;
          orgPortalBase = apps.ORG_PORTAL.ENV.LOCAL.URL_BASE;
          siteAdminBase = apps.SITE_ADMIN.ENV.LOCAL.URL_BASE;
          accountSettingsBase = apps.ACCOUNT_SETTINGS.ENV.LOCAL.URL_BASE;
          customerAccountSettingsBase =
            apps.CUSTOMER_ACCOUNT_SETTINGS.ENV.LOCAL.URL_BASE;
          orgSettingsBase = apps.ORG_SETTINGS.ENV.LOCAL.URL_BASE;
          cmBase = apps.CM.ENV.LOCAL.URL_BASE;
          catalogsBase = apps.CATALOGS.ENV.LOCAL.URL_BASE;
          portalHeaders["x-client-secret"] = PORTAL_SECRET.LOCAL;
          break;
        case service.BETA.DOMAIN:
          environment = service.BETA;
          apiBase = apps.CORE.ENV.BETA.URL_BASE;
          desktopBase = apps.DESKTOP.ENV.BETA.URL_BASE;
          mobileBase = apps.MOBILE.ENV.BETA.URL_BASE;
          orgPortalBase = apps.ORG_PORTAL.ENV.BETA.URL_BASE;
          siteAdminBase = apps.SITE_ADMIN.ENV.BETA.URL_BASE;
          accountSettingsBase = apps.ACCOUNT_SETTINGS.ENV.BETA.URL_BASE;
          customerAccountSettingsBase =
            apps.CUSTOMER_ACCOUNT_SETTINGS.ENV.BETA.URL_BASE;
          orgSettingsBase = apps.ORG_SETTINGS.ENV.BETA.URL_BASE;
          cmBase = apps.CM.ENV.BETA.URL_BASE;
          catalogsBase = apps.CATALOGS.ENV.BETA.URL_BASE;
          portalHeaders["x-client-secret"] = PORTAL_SECRET.BETA;
          break;
        case service.TEMP.DOMAIN:
          environment = service.TEMP;
          apiBase = apps.CORE.ENV.TEMP.URL_BASE;
          desktopBase = apps.DESKTOP.ENV.TEMP.URL_BASE;
          mobileBase = apps.MOBILE.ENV.TEMP.URL_BASE;
          orgPortalBase = apps.ORG_PORTAL.ENV.TEMP.URL_BASE;
          siteAdminBase = apps.SITE_ADMIN.ENV.TEMP.URL_BASE;
          accountSettingsBase = apps.ACCOUNT_SETTINGS.ENV.TEMP.URL_BASE;
          customerAccountSettingsBase =
            apps.CUSTOMER_ACCOUNT_SETTINGS.ENV.TEMP.URL_BASE;
          orgSettingsBase = apps.ORG_SETTINGS.ENV.TEMP.URL_BASE;
          cmBase = apps.CM.ENV.TEMP.URL_BASE;
          catalogsBase = apps.CATALOGS.ENV.TEMP.URL_BASE;
          portalHeaders["x-client-secret"] = PORTAL_SECRET.TEMP;
          break;
        case service.TEST.DOMAIN:
          environment = service.TEST;
          apiBase = apps.CORE.ENV.PRODUCTION.URL_BASE;
          desktopBase = apps.DESKTOP.ENV.PRODUCTION.URL_BASE;
          mobileBase = apps.MOBILE.ENV.PRODUCTION.URL_BASE;
          orgPortalBase = apps.ORG_PORTAL.ENV.PRODUCTION.URL_BASE;
          siteAdminBase = apps.SITE_ADMIN.ENV.PRODUCTION.URL_BASE;
          accountSettingsBase = apps.ACCOUNT_SETTINGS.ENV.PRODUCTION.URL_BASE;
          customerAccountSettingsBase =
            apps.CUSTOMER_ACCOUNT_SETTINGS.ENV.PRODUCTION.URL_BASE;
          orgSettingsBase = apps.ORG_SETTINGS.ENV.PRODUCTION.URL_BASE;
          cmBase = apps.CM.ENV.PRODUCTION.URL_BASE;
          catalogsBase = apps.CATALOGS.ENV.PRODUCTION.URL_BASE;
          portalHeaders["x-client-secret"] = PORTAL_SECRET.PRODUCTION;
          break;
        default:
          environment = service.PRODUCTION;
          apiBase = apps.CORE.ENV.PRODUCTION.URL_BASE;
          desktopBase = apps.DESKTOP.ENV.PRODUCTION.URL_BASE;
          mobileBase = apps.MOBILE.ENV.PRODUCTION.URL_BASE;
          orgPortalBase = apps.ORG_PORTAL.ENV.PRODUCTION.URL_BASE;
          siteAdminBase = apps.SITE_ADMIN.ENV.PRODUCTION.URL_BASE;
          accountSettingsBase = apps.ACCOUNT_SETTINGS.ENV.PRODUCTION.URL_BASE;
          customerAccountSettingsBase =
            apps.CUSTOMER_ACCOUNT_SETTINGS.ENV.PRODUCTION.URL_BASE;
          orgSettingsBase = apps.ORG_SETTINGS.ENV.PRODUCTION.URL_BASE;
          cmBase = apps.CM.ENV.PRODUCTION.URL_BASE;
          catalogsBase = apps.CATALOGS.ENV.PRODUCTION.URL_BASE;
          portalHeaders["x-client-secret"] = PORTAL_SECRET.PRODUCTION;
      }
      // Browser Agent
      browser.name = Sniffr.browser.name;
      browser.version = Sniffr.browser.version;
      browser.versionString = Sniffr.browser.versionString;
      os = Sniffr.os.name;

      // Check supported versions
      switch (browser.name) {
        case "ie":
          browser.outdated = true;
          break;
        case "chrome":
          if (browser.version[0] < 57) {
            browser.outdated = true;
          }
          break;
        case "firefox":
          if (browser.version[0] < 52) {
            browser.outdated = true;
          }
          break;
        case "edge":
          if (browser.version[0] < 12) {
            browser.outdated = true;
          }
          break;
        case "safari":
          if (os === "ios") {
            if (parseFloat(browser.versionString) < 10.3) {
              browser.outdated = true;
            }
          } else if (os === "macos") {
            if (parseFloat(browser.versionString) < 10.1) {
              browser.outdated = true;
            }
          }
          break;
        default:
          browser.outdated = false;
      }

      browser.mobile = isMobile();

      // Android can be any number of devices so can only check os
      browser.isAndroid = os === "android";
      browser.isIphone = Sniffr.device.name === "iphone";
    }

    function isMobile() {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          navigator.userAgent.substr(0, 4)
        )
      );
    }
  }
})();
