import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  getGridStringOperators,
  getGridSingleSelectOperators,
  getGridNumericOperators,
  getGridDateOperators,
  ValueOptions,
  GridFilterModel,
  GridSortModel,
  useGridApiRef,
  useGridApiContext,
  GridActionsCellItem,
  GridRenderEditCellParams,
  GridRowModes,
  GridRowModesModel,
  GridRowEditStartParams,
  GridRowEditStartReasons,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowSelectionModel,
  GridEventListener,
  GridRenderCellParams,
  GridEditBooleanCell,
  GridEditDateCell,
  GridEditInputCell,
  GridEditSingleSelectCell,
  MuiEvent,
  GridActionsColDef,
} from "@mui/x-data-grid-pro";

import {
  OrganizationResponse,
  BuildingResponse,
  PinTypeResponse,
  PinFieldDataType,
  UserResponse,
  WebAssetResponse,
} from "@akitabox/api-client";
import { makeUseServiceCall } from "../hooks/useServiceCall";
import { api } from "../api";
import { useRouteLoaderData } from "react-router";
import { useLocation, useSearchParams } from "react-router-dom";

import { useInfiniteCall } from "../hooks/useInfiniteCall";
import { useApiMutation } from "../hooks/useApiMutation";

import {
  Box,
  MenuItem,
  Snackbar,
  Tooltip,
  Popper,
  Paper,
  InputBase,
  InputBaseProps,
  Badge,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import {
  AccountTree,
  Announcement,
  Build,
  Cancel,
  Delete,
  Edit,
  Event,
  Place,
  LocationOff,
  RemoveCircle,
  Save,
  PhotoLibrary,
  Launch,
  Grading,
  CurrencyExchange,
} from "@mui/icons-material";

import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";

import ActiveViewToolbar from "./ActiveViewToolbar";
import GridToolbar from "./GridToolbar";
import ListPagination from "../components/ListPagination";
import ExportDrawer from "./ExportDrawer";
import { AttachmentsPreview, HasAttachmentsFilter } from "./AttachmentsPreview";
import { LocationPreview } from "./LocationPreview";
import SideDrawer from "./SideDrawer";
import { stylesheet } from "../stylesheet";
import { FilterTerms, DetailSettings } from "./types";
import { useAngularApp } from "../legacy/angularApp";
import {
  handleLegacyAddServiceRequest,
  handleLegacyAddWorkOrder,
  handleLegacyAddMaintenanceSchedule,
  handleLegacyDecommission,
  handleLegacyDelete,
  handleLegacyCondition,
  handleLegacyLifeCycleSelection,
} from "./assetActionsUtils";
import { useListContext } from "./ListContext";
import { getTypeaheadSelectOperator } from "../components/SearchableSingleSelectFilter";
import { getDatePickerOperator } from "../components/DateRangeHeaderFilter";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const ss = stylesheet({
  dataGridWrapper: {
    ".MuiDataGrid-virtualScroller": {
      overflowY: "visible!important" as any,
    },
    background: "#ffffff",
    paddingTop: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "scroll",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100% - 64px)", // Adjust based on the height of the toolbar
  },
  dataGridContainer: {
    flex: 1,
    overflow: "auto",
  },
  sideDrawerContainer: {
    overflow: "auto",
    position: "relative",
    zIndex: 0,
  },
  relativeDrawer: {
    position: "relative",
  },
});
export interface AssetSelectionDataGridFilters {
  values?: any;
  name?: string;
  pinType?: string;
  building?: string;
  level?: string;
  condition?: string;
  total_cost_with_adjustments?: string;
  has_attachments?: boolean;
  decommissioned?: boolean;
  installation_date?: string;
}

export interface AssetListProps {
  filters?: any;
  onFilterChange?: (filters: AssetSelectionDataGridFilters) => void;
}
const isValidDate = (value: any) => {
  if (value === null || value === undefined) {
    return false;
  }
  const date = new Date(value);
  return !isNaN(date.getTime()); // getTime() returns NaN for invalid dates
};

function operatorToQuery(operator: string) {
  switch (operator) {
    case "is":
    case "equals":
      return "$eq";
    case "notEquals":
      return "$ne";
    case ">":
    case "after":
      return "$gt";
    case ">=":
      return "$gte";
    case "<":
    case "before":
      return "$lt";
    case "<=":
      return "$lte";
    case "contains":
      return "$regex";
    case "isEmpty":
      return "$exists,false";
    case "isNotEmpty":
      return "$exists,true";
    default:
      return "$eq";
  }
}

function queryToOperator(query: string) {
  switch (query) {
    case "$eq":
      return "equals";
    case "$ne":
      return "notEquals";
    case "$gt":
      return ">";
    case "$gte":
      return ">=";
    case "$lt":
      return "<";
    case "$lte":
      return "<=";
    case "$regex":
      return "contains";
    case "$exists,false":
      return "isEmpty";
    case "$exists,true":
      return "isNotEmpty";
    case "$in":
      return "is";
    case "$nin":
      return "isNot";
    default:
      return query;
  }
}

export function EditCustomDate(props: GridRenderEditCellParams<any, Date>) {
  const { value, id, field, date_format } = props;

  const apiRef = useGridApiContext();
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        value={
          isValidDate(value) ? moment(value) : value ? moment(value) : null
        }
        onChange={(date) => {
          let newDate = date;
          if (date_format === "YYYY" && date) {
            newDate = moment(date).startOf("year").add(1, "day");
          }
          apiRef.current.setEditCellValue({
            id: id,
            field: field,
            value: newDate?.toDate() || null,
          });
        }}
        format={date_format || "MM/DD/YYYY"}
        views={["year"]}
        defaultValue={null}
        slotProps={{ field: { shouldRespectLeadingZeros: true } }}
      />
    </LocalizationProvider>
  );
}

function EditTextarea(props: GridRenderEditCellParams<any, string>) {
  const { id, field, value, hasFocus } = props;
  const ref = React.useRef<HTMLElement>(null);
  const [valueState, setValueState] = React.useState(value);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null);
  const apiRef = useGridApiContext();

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(
        `input[value="${value}"]`
      );
      input?.focus();
    }
  }, [hasFocus, value]);

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, inputRef]);

  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  const handleChange = React.useCallback<
    NonNullable<InputBaseProps["onChange"]>
  >(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: 200,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper elevation={1} sx={{ p: 1, minWidth: 200 }}>
            <InputBase
              multiline
              rows={4}
              value={valueState}
              sx={{ textarea: { resize: "both" }, width: "100%" }}
              onChange={handleChange}
              inputRef={(ref) => setInputRef(ref)}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}
const singleSelectIsAnyOfOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === "isAnyOf"
);
const singleSelectIsOperator = getGridSingleSelectOperators().filter(
  (operator) => operator.value === "is"
);
const stringFilterOperators = getGridStringOperators().filter(
  (o) => o.value === "contains" || o.value === "equals"
);
const numericFilterOperators = getGridNumericOperators().filter(
  (o) =>
    o.value !== "isEmpty" && o.value !== "isNotEmpty" && o.value !== "isAnyOf"
);
const numericFilterOperatorsExtended = getGridNumericOperators().filter(
  (o) => o.value !== "isAnyOf"
);
const BaseExportFieldIds = {
  name: "_id",
  "building.name": "Building _id",
  "pinType.name": "Asset Category _id",
  "level.name": "Floor _id",
  "room.number": "Room _id",
};
const BaseExportFields = {
  "building.name": "Building Name",
  "pinType.name": "Asset Category Name",
  name: "Asset Name",
  installation_date: "Installation Date",
  percentX: "Percent X",
  percentY: "Percent Y",
  size: "Size",
  size_unit: "Size Unit",
  quantity: "Quantity",
  quantity_unit: "Quantity Unit",
  "level.name": "Floor Name",
  "room.number": "Room Number",
  "costlines.total_cost": "Total Cost",
  "costlines.total_cost_with_adjustments": "Total Cost with Adjustments",
};
const BaseExportExtended = {
  name: ["Asset URL", "Number"],
};

export const AssetList: FunctionComponent<AssetListProps> = () => {
  const apiRef = useGridApiRef();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { refreshList, setRefreshList } = useListContext();

  const { organization, user, flags } = useRouteLoaderData("shell") as {
    organization: OrganizationResponse;
    user: UserResponse;
    flags: any;
  };

  const permissions = useMemo(
    () => ({
      canDecommissionAsset: user.permission_group.asset["decommission"],
      canRecommissionAsset: user.permission_group.asset["recommission"],
      canDeleteAsset: user.permission_group.asset["remove"],
      canAddRequest:
        organization.show_tasks && user.permission_group.request["create"],
      canAddWorkOrder:
        organization.show_tasks && user.permission_group.task["create"],
      canAddSchedule:
        organization.show_tasks && user.permission_group.future_task["create"],
      canManageAssociations: user.permission_group.association["create"],
      canManageCostAdjustments:
        flags["asset_life_cycle"] &&
        user.permission_group.cost_adjustment["create"] &&
        user.permission_group.cost_adjustment["remove"] &&
        user.permission_group.cost_adjustment["update"],
      canManageLifeCycle:
        organization.show_fca &&
        flags["asset_life_cycle"] &&
        user.permission_group.cost_line["create"] &&
        user.permission_group.cost_line["update"] &&
        user.permission_group.cost_line["remove"],
      canUpdateAsset: user.permission_group.asset["update"],
    }),
    [user.permission_group, organization, flags]
  );
  const { asset: list_view } = user.permission_group;
  const assetPermissions = useMemo(
    () => ({
      read: list_view["read"],
      update: list_view["update"],
    }),
    [list_view]
  );

  const allowedPageSizes = [25, 50, 100];

  const [filters, setFilters] = useState<AssetSelectionDataGridFilters>();
  const [showHeaderFilters, setShowHeaderFilters] = useState<boolean>(false);
  const [visibleFilterTerms, setVisibleFilterTerms] = useState<FilterTerms[]>(
    []
  );
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  const [exportColumns, setExportColumns] = useState<string[]>([]);

  const defaultSettings: DetailSettings = {
    showAttachments: {
      state: true,
      displayName: "Show Attachments",
      iconName: "PhotoLibrary",
    },
    showLocation: {
      state: true,
      displayName: "Show Locations",
      iconName: "Place",
    },
    // showAssociations: {
    //   state: false,
    //   displayName: "Show Associations",
    //   iconName: "AccountTree",
    // },
    showTotalCostWithAdjustments: {
      state: false,
      displayName: "Show Total Cost (Adjusted)",
      iconName: "CurrencyExchange",
    },
    showConditions: {
      state: false,
      displayName: "Show Conditions",
      iconName: "Grading",
    },
  };
  const getInitialSettings = (): DetailSettings => {
    const storedSettings = localStorage.getItem("assetlist_detail");
    let settings = storedSettings
      ? JSON.parse(storedSettings)
      : defaultSettings;

    // Migration step: Add showTotalCostWithAdjustments if it is missing
    if (!settings.showTotalCostWithAdjustments) {
      settings = {
        ...settings,
        showTotalCostWithAdjustments:
          defaultSettings.showTotalCostWithAdjustments,
      };
      localStorage.setItem("assetlist_detail", JSON.stringify(settings));
    }
    // Migration step: Add showConditions if it is missing
    if (!settings.showConditions) {
      settings = {
        ...settings,
        showConditions: defaultSettings.showConditions,
      };
      localStorage.setItem("assetlist_detail", JSON.stringify(settings));
    }
    return settings;
  };
  const [detailSettings, setDetailSettings] = useState<DetailSettings>(
    getInitialSettings()
  );
  const updateSetting = (settingName: string, newState: boolean) => {
    setDetailSettings((prevSettings) => ({
      ...prevSettings,
      [settingName]: { ...prevSettings[settingName], state: newState },
    }));
  };
  const getInitialSidebarOpen = (): boolean => {
    const storedSidebarOpen = localStorage.getItem("assetlist_sidebar");
    return storedSidebarOpen ? JSON.parse(storedSidebarOpen) : false;
  };

  const [sidebarOpen, setSidebarOpen] = useState(getInitialSidebarOpen());

  const updateSidebarSetting = (newState: boolean) => {
    setSidebarOpen(newState);
  };
  const [checkboxVisible, setCheckboxVisible] = useState(false);
  const [showDecommissioned, setShowDecommissioned] = useState(false);
  const updateDecommissionedSetting = (newState: boolean) => {
    setShowDecommissioned(newState);
  };
  const [exportDrawerOpen, setExportDrawerOpen] = useState(false);
  const handleToggleCheckboxSetting = () => {
    setCheckboxVisible((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("assetlist_detail", JSON.stringify(detailSettings));
    localStorage.setItem("assetlist_sidebar", JSON.stringify(sidebarOpen));
  }, [detailSettings, sidebarOpen]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: allowedPageSizes[0],
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const handleSaveClick = useCallback(
    (assetId: string) => {
      const rowModes = { ...rowModesModel };
      rowModes[assetId] = { mode: GridRowModes.View };
      setRowModesModel(rowModes);
    },
    [rowModesModel]
  );
  const handleEditClick = useCallback(
    (assetId: string) => {
      const rowModes = { ...rowModesModel };
      rowModes[assetId] = { mode: GridRowModes.Edit };
      setRowModesModel(rowModes);
    },
    [rowModesModel]
  );
  const handleCancelClick = useCallback(
    (assetId: string) => () => {
      const rowModes = { ...rowModesModel };
      rowModes[assetId] = {
        mode: GridRowModes.View,
        ignoreModifications: true,
      };
      setRowModesModel(rowModes);
    },
    [rowModesModel]
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [currentPreview, setCurrentPreview] = useState(""); // or 'location', or null
  const [attachmentAsset, setAttachmentAsset] = useState<any>();
  const handleAttachmentsPreviewOpen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>,
    asset: any
  ) => {
    setAnchorEl(event.currentTarget);
    setAttachmentAsset(asset);
    setCurrentPreview("attachments");
  };
  const handleAttachmentsPreviewClose = () => {
    setAnchorEl(null);
    setAttachmentAsset("");
    setCurrentPreview("");
  };

  const [locationAsset, setLocationAsset] = useState<any>();
  const handleLocationPreviewOpen = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>,
    asset: any
  ) => {
    setAnchorEl(event.currentTarget);
    setLocationAsset(asset);
    setCurrentPreview("location");
  };
  const handleLocationPreviewClose = () => {
    setAnchorEl(null);
    setLocationAsset("");
    setCurrentPreview("");
  };

  // Find all of the buildings in the organization
  const { data: buildingResponse } = makeUseServiceCall(
    api.buildings.getByOrganization
  )({
    organizationId: organization._id,
    skip: 0,
    limit: 1000,
  });
  const buildings = useMemo(() => {
    return buildingResponse ? buildingResponse.data : [];
  }, [buildingResponse]);
  const buildingOptions = useMemo(() => {
    return buildings
      .sort((a, b) => a?.name?.localeCompare(b?.name))
      .map((building) => ({
        label: building?.name,
        value: building?._id,
      }));
  }, [buildings]);

  const { data: pinTypesCountResponse } = makeUseServiceCall(
    api.pinTypes.count
  )({
    organizationId: organization._id,
  });
  const pinTypesCount = useMemo(() => {
    return pinTypesCountResponse ? pinTypesCountResponse.data.count : 0;
  }, [pinTypesCountResponse]);

  const [pinTypes, setPinTypes] = useState<PinTypeResponse[]>([]);
  useEffect(() => {
    const fetchAllPinTypes = async () => {
      if (pinTypesCountResponse) {
        let allPinTypes: PinTypeResponse[] = [];
        while (allPinTypes.length < pinTypesCount) {
          const { data: pinTypesResponse } =
            await api.pinTypes.getByOrganization({
              organizationId: organization._id,
              skip: allPinTypes.length,
              limit: 1000,
            });
          allPinTypes = [...allPinTypes, ...pinTypesResponse];
        }
        setPinTypes(allPinTypes);
      }
    };
    fetchAllPinTypes();
  }, [pinTypesCountResponse, organization._id, pinTypesCount]);

  const pinTypeOptions = useMemo(() => {
    return pinTypes.reduce(
      (acc: { label: string; value: string }[], pinType) => {
        const pinTypeName = `${pinType.name}`;
        // Check if the pinType.name already exists in the accumulator
        if (!acc.find((option) => option.value === pinTypeName)) {
          // If it doesn't exist, add it to the accumulator
          acc.push({
            label: pinTypeName,
            value: pinTypeName,
          });
        }

        return acc;
      },
      []
    );
  }, [pinTypes]);

  // Look across all pinTypes and find the distinct list of acceptable_enum_values for the field named Type
  const pinTypeFieldTypeOptions = useMemo(() => {
    let pinFieldOptions = pinTypes
      .reduce(
        (
          acc: {
            label: string;
            value: string;
            building: string;
            pinType: string;
          }[],
          pinType
        ) => {
          const pinTypeField = pinType.fields.find(
            (field) => field.name === "Type"
          );
          if (pinTypeField) {
            pinTypeField?.acceptable_enum_values?.forEach((value) => {
              if (!acc.find((option) => option.value === value)) {
                acc.push({
                  label: value,
                  value,
                  building: pinType.building,
                  pinType: pinType.name,
                });
              }
            });
          }
          return acc;
        },
        []
      )
      .sort((a, b) => a?.label?.localeCompare(b?.label));
    if (filterModel) {
      const items = filterModel.items;
      const buildingFilter = items.find(
        ({ field }) => field === "building.name"
      );
      if (buildingFilter && buildingFilter.value) {
        const buildingId = buildingFilter.value;
        if (buildingId) {
          pinFieldOptions = pinFieldOptions.filter(
            (option) => option.building === buildingId
          );
        }
      }
      const pinTypeFilter = items.find(({ field }) => field === "pinType.name");
      if (pinTypeFilter && pinTypeFilter.value) {
        const pinType = pinTypeFilter.value;
        if (pinType) {
          pinFieldOptions = pinFieldOptions.filter(
            (option) => option.pinType === pinType
          );
        }
      }
      return pinFieldOptions;
    }
    return pinFieldOptions;
  }, [filterModel, pinTypes]);

  const uniquePinTypeFields = useMemo(() => {
    return Array.from(
      new Set(
        pinTypes.reduce(
          (acc: string[], pinType) => [
            ...acc,
            ...pinType.fields.map((field) => field.name),
          ],
          []
        )
      )
    );
  }, [pinTypes]);

  const allPinTypeFields = useMemo(() => {
    return pinTypes.flatMap((pinType) => pinType.fields);
  }, [pinTypes]);

  // Find all of the Levels in the organization
  const { data: orgLevelsResponse } = makeUseServiceCall(
    api.levels.byOrganization
  )({
    organizationId: organization._id,
    skip: 0,
    limit: 1000,
  });
  const orgLevels = useMemo(() => {
    return orgLevelsResponse ? orgLevelsResponse.data : [];
  }, [orgLevelsResponse]);
  const orgLevelsOptions = useMemo(() => {
    function getBuildingName(level: any) {
      return buildings.find((building) => building._id === level.building)
        ?.name;
    }
    const levelOptions = orgLevels
      .map((level) => ({
        label: level?.name,
        value: level._id,
        building: level.building,
        group: getBuildingName(level),
      }))
      .sort((a, b) => a?.label.localeCompare(b?.label));
    if (filterModel) {
      const items = filterModel.items;
      const buildingFilter = items.find(
        ({ field }) => field === "building.name"
      );
      if (buildingFilter && buildingFilter.value) {
        const buildingId = buildingFilter.value;
        if (buildingId) {
          return levelOptions.filter(
            (option) => option.building === buildingId
          );
        }
      }
    }
    return levelOptions;
  }, [orgLevels, buildings, filterModel]);

  const conditionOptions = useMemo(() => {
    return [
      { label: "Very Good", value: "Very Good" },
      { label: "Good", value: "Good" },
      { label: "Fair", value: "Fair" },
      { label: "Poor", value: "Poor" },
      { label: "Very Poor", value: "Very Poor" },
      { label: "Failing", value: "Failing" },
    ];
  }, []);

  const onSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel.length) {
      setSortModel([{ field: "name", sort: "asc" }]);
      return;
    }
    const sortItem = sortModel[0];
    if (!sortItem) return;
    let field = sortItem.field;
    if (field === "building") {
      field = "building.name";
    } else if (field === "level") {
      field = "level.name";
    } else if (field === "room") {
      field = "room.number";
    }

    setSortModel([
      {
        field: field,
        sort: sortItem.sort,
      },
    ]);
  }, []);

  const injector = useAngularApp();

  const addServiceRequest = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) =>
      handleLegacyAddServiceRequest(injector, organization, building, assets),
    [injector]
  );
  const addWorkOrder = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) => handleLegacyAddWorkOrder(injector, organization, building, assets),
    [injector]
  );
  const addMaintenanceSchedule = useCallback(
    (
      organization: OrganizationResponse,
      building: BuildingResponse,
      assets: WebAssetResponse[]
    ) =>
      handleLegacyAddMaintenanceSchedule(
        injector,
        organization,
        building,
        assets
      ),
    [injector]
  );
  const decommissionAsset = useCallback(
    (assets: WebAssetResponse[]) => {
      handleLegacyDecommission(injector, assets).then(() => {
        setRefreshList(true);
      });
    },
    [injector, setRefreshList]
  );
  const deleteAsset = useCallback(
    (assets: WebAssetResponse[]) => {
      handleLegacyDelete(injector, assets).then(() => {
        setRefreshList(true);
      });
    },
    [injector, setRefreshList]
  );
  const addConditionRating = useCallback(
    (asset: WebAssetResponse) => {
      handleLegacyCondition(injector, asset).then(() => {
        setTimeout(() => {
          setRefreshList(true);
        }, 1000);
      });
    },
    [injector, setRefreshList]
  );
  const addLifeCycleCost = useCallback(
    (asset: WebAssetResponse) => {
      handleLegacyLifeCycleSelection(injector, [asset]).then(() => {
        setTimeout(() => {
          setRefreshList(true);
        }, 1000);
      });
    },
    [injector, setRefreshList]
  );
  const [attachmentFilterState, setAttachmentFilterState] = useState<
    boolean | null
  >(null);

  const [columnsLoaded, setColumnsLoaded] = useState(false);
  const baseColumns: GridColDef<WebAssetResponse>[] = useMemo(():
    | GridColDef<WebAssetResponse>[] => {
    const base: GridColDef<WebAssetResponse>[] = [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
      },
      {
        field: "name",
        type: "string",
        headerName: "Name",
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === "contains"
        ),
        renderCell: ({ row }: GridRenderCellParams<WebAssetResponse>) => {
          const asset = row;
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="abx-pin-type-icon-wrap"
                  style={{
                    backgroundColor: `${asset.color}`,
                  }}
                >
                  <img
                    src={`/img/pin_icons/${asset.icon}.svg`}
                    alt={asset.display_name}
                    style={{
                      width: 20,
                      height: 20,
                      color: `${asset.color}`,
                    }}
                  />
                </div>
                <a href={`/assets/${asset._id}/overview`}>
                  {asset.display_name}
                </a>
              </div>
            </>
          );
        },
        valueGetter: (value: any, row: any) => {
          return row?.display_name;
        },
        editable: true,
        sortable: true,
        filterable: true,
        minWidth: 200,
      },
      {
        field: "building.name",
        type: "singleSelect",
        headerName: "Building",
        valueOptions: buildingOptions,
        renderCell: ({ row }: GridRenderCellParams<WebAssetResponse>) =>
          row.building.name,
        valueFormatter: (value: any) => {
          return value?.name;
        },
        sortable: true,
        filterable: true,
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(buildingOptions),
        ],
        editable: false,
        minWidth: 150,
      },
      {
        field: "pinType.name",
        type: "singleSelect",
        headerName: "Category",
        filterOperators: [
          ...singleSelectIsAnyOfOperator,
          ...getTypeaheadSelectOperator(pinTypeOptions),
        ],
        valueOptions: pinTypeOptions,
        renderCell: ({ row }: GridRenderCellParams<WebAssetResponse>) =>
          row.pinType.name,
        valueFormatter: (value: any) => {
          return value?.name;
        },
        sortable: true,
        filterable: true,
        editable: false,
        minWidth: 150,
      },
      {
        field: "level.name",
        type: "singleSelect",
        headerName: "Floor",
        valueOptions: orgLevelsOptions,
        renderCell: ({ row }: GridRenderCellParams<WebAssetResponse>) =>
          row.level?.name,
        valueFormatter: (value: any) => {
          return value?.name;
        },
        editable: false,
        filterable: true,
        filterOperators: getTypeaheadSelectOperator(orgLevelsOptions),
        sortable: true,
        minWidth: 150,
      },
      {
        field: "room.number",
        type: "string",
        headerName: "Room",
        renderCell: ({ row }: GridRenderCellParams<WebAssetResponse>) =>
          row.room?.number,
        valueFormatter: (value: any) => {
          return value?.number;
        },
        editable: false,
        filterable: false,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === "contains"
        ),
        sortable: true,
        minWidth: 150,
      },
      {
        field: "costlines.total_cost",
        type: "number",
        headerName: "Total Cost",
        renderCell: ({
          row: asset,
        }: GridRenderCellParams<WebAssetResponse>) => {
          if (asset.costlines && asset.costlines.length > 0) {
            const costLine = asset.costlines[0];
            return typeof costLine.total_cost === "number"
              ? `$${costLine.total_cost.toFixed(2)}`
              : "";
          } else {
            return "";
          }
        },
        editable: false,
        filterable: false,
        sortable: false,
        minWidth: 150,
      },
    ];
    if (detailSettings.showTotalCostWithAdjustments.state) {
      base.push({
        field: "costlines.total_cost_with_adjustments",
        type: "number",
        headerName: "Total Cost (Adjusted)",
        renderCell: ({ row: asset }) => {
          if (asset.costlines && asset.costlines.length > 0) {
            const costLine = asset.costlines[0];
            return typeof costLine.total_cost_with_adjustments === "number"
              ? `$${costLine.total_cost_with_adjustments.toFixed(2)}`
              : "";
          } else {
            return "";
          }
        },
        editable: false,
        filterable: true,
        filterOperators: numericFilterOperatorsExtended,
        sortable: true,
        minWidth: 170,
      });
    }

    if (organization.show_condition_field) {
      base.push({
        field: "condition",
        type: "singleSelect",
        filterable: true,
        headerName: "Condition",
        renderCell: ({ row: asset }) => {
          return asset.condition?.condition_rating;
        },
        valueFormatter: (value: any) => {
          return value?.condition_rating;
        },
        valueOptions: conditionOptions,
        filterOperators: singleSelectIsOperator,
        minWidth: 150,
      });
    }

    if (organization.show_installation_date_field) {
      base.push({
        field: "installation_date",
        type: "date",
        headerName: "Installation Date",
        renderCell: ({ row: asset }) => {
          return asset.installation_date
            ? new Date(asset.installation_date).getFullYear()
            : "";
        },
        valueFormatter: (value: any) => {
          return value ? new Date(value).getFullYear() : "";
        },
        valueGetter: (value, row) => {
          if (!row.installation_date) {
            return "";
          }
          return new Date(row.installation_date);
        },
        editable: true,
        filterable: true,
        filterOperators: getDatePickerOperator(
          ["year"],
          ["is", "after", "before", "isEmpty", "isNotEmpty"]
        ),
        minWidth: 150,
        renderEditCell: (params) => (
          <EditCustomDate
            {...params}
            value={params.value}
            date_format={"YYYY"}
            type="date"
          />
        ),
      });
      return base;
    }
    return base;
  }, [
    buildingOptions,
    conditionOptions,
    detailSettings.showTotalCostWithAdjustments.state,
    orgLevelsOptions,
    organization.show_condition_field,
    organization.show_installation_date_field,
    pinTypeOptions,
  ]);

  // Build the columns in the data grid
  //  This utilizes the custom values in the assets to define the columns
  //  This also uses the orgLevels to define the levels for the levels column
  //  This also uses the buildings to define the buildings for the building column
  //  This also uses the pinTypes to define the pinTypes for the pinType column
  const customColumns = useMemo<GridColDef<WebAssetResponse>[]>(() => {
    function getFieldDefinition(field: string, row: any) {
      const fieldDefinition = {
        data_type: "text",
        acceptable_enum_values: [],
        is_editable: false,
        is_required: false,
      };
      if (row.pinType.fields) {
        const fd = row.pinType.fields.find(
          (fieldDef: any) => fieldDef?.name === field?.replace("values.", "")
        );
        if (fd) {
          fieldDefinition.data_type = fd.data_type;
          fieldDefinition.acceptable_enum_values = fd.acceptable_enum_values;
          fieldDefinition.is_editable = fd.is_editable;
          fieldDefinition.is_required = fd.is_required;
        }
        return fieldDefinition;
      }
      return fieldDefinition;
    }

    function getColumnValueOptions(columnName: string) {
      return pinTypes
        .reduce((acc: { label: string; value: string }[], pinType) => {
          const pinTypeField = pinType.fields.find(
            (field) => field?.name === columnName
          );
          if (pinTypeField) {
            pinTypeField?.acceptable_enum_values?.forEach((value) => {
              if (!acc.find((option) => option.value === value)) {
                acc.push({
                  label: value,
                  value,
                });
              }
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }

    const customColumns: GridColDef[] = [
      ...uniquePinTypeFields
        // filter out any values where the _pinField has the property is_hidden set to true
        .filter((key) => {
          // Find the first example of this field name in the pinTypes
          const field = allPinTypeFields.find((field) => field.name === key);
          if (
            field?.is_hidden ||
            !!field?.is_level_field ||
            !!field?.is_room_field ||
            field?.data_type === "document_array" ||
            field?.data_type === "tag_filter"
          ) {
            return false;
          }
          return true;
        })
        .map((key) => {
          const field = allPinTypeFields.find((field) => field.name === key);
          let valueOptions: ValueOptions = [];

          let type = "string";
          if (field?.data_type === "float" || field?.data_type === "int") {
            type = "number";
          } else if (field?.data_type === "enum") {
            type = "singleSelect";
            valueOptions = getColumnValueOptions(field?.name);
          } else if (field?.data_type === "date") {
            type = "date";
          }

          let filterOperators;
          if (field?.name === "Type") {
            type = "singleSelect";
            filterOperators = [
              ...singleSelectIsAnyOfOperator,
              ...getTypeaheadSelectOperator(buildingOptions),
            ];
            valueOptions = pinTypeFieldTypeOptions;
          } else {
            if (field?.data_type === "float" || field?.data_type === "int") {
              filterOperators = numericFilterOperators;
            } else if (field?.data_type === "enum") {
              filterOperators = singleSelectIsOperator;
            } else if (field?.data_type === "date") {
              filterOperators = getGridDateOperators();
            } else {
              filterOperators = stringFilterOperators;
            }
          }

          return {
            field: `values.${key}`,
            renderCell: (params: GridRenderCellParams<any>) => {
              const fieldDefinition = getFieldDefinition(
                params.field,
                params.row
              );
              const { data_type } = fieldDefinition
                ? (fieldDefinition as {
                    data_type: PinFieldDataType;
                  })
                : {
                    data_type: "text",
                  };
              if (data_type === "url") {
                return (
                  <a
                    href={params.row.values[key].value}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {params.row.values[key].value}
                  </a>
                );
              }
              return params.formattedValue;
            },
            valueFormatter: (value: any, row: any) => {
              if (row.values[key] === undefined) return "";
              if (field?.data_type === "date" && row.values[key].value) {
                return new Date(row.values[key].value).toLocaleDateString(
                  "en-US",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  }
                );
              }
              return row.values[key].value;
            },
            valueGetter: (value: any, row: any) => {
              if (row.values[key] === undefined) return "";
              if (field?.data_type === "date" && row.values[key].value) {
                return new Date(row.values[key].value);
              }
              return row.values[key].value;
            },
            valueSetter: (value: any, row: any) => {
              const newRow = {
                ...row,
                values: { ...row.values, [key]: { ...row.values[key], value } },
              };
              return newRow;
            },
            headerName: key,
            minWidth: 150,
            type: type as
              | "string"
              | "number"
              | "singleSelect"
              | "date"
              | "boolean",
            editable: true,
            preProcessEditCellProps: (params: any) => {
              const newValue = params.props.value;
              if (field?.data_type === "date" && newValue) {
                return {
                  ...params.props,
                  value: new Date(newValue).toISOString(),
                };
              }
              if (field?.data_type === "enum") {
                const fieldDefinition = getFieldDefinition(
                  params.field,
                  params.row
                );
                const { acceptable_enum_values } = fieldDefinition
                  ? (fieldDefinition as {
                      acceptable_enum_values: string[];
                    })
                  : {
                      acceptable_enum_values: [],
                    };
                return { ...params.props, options: acceptable_enum_values };
              }
              return params.props;
            },
            renderEditCell: (params: any) => {
              const fieldDefinition = getFieldDefinition(
                params.field,
                params.row
              );
              const {
                data_type,
                acceptable_enum_values,
                is_editable,
                is_required,
              } = fieldDefinition
                ? (fieldDefinition as {
                    data_type: PinFieldDataType;
                    acceptable_enum_values: string[];
                    is_editable: boolean;
                    is_required: boolean;
                  })
                : {
                    data_type: "text",
                    acceptable_enum_values: [],
                    is_editable: false,
                    is_required: false,
                  };
              // GridEditSingleSelectCell is really nice, but it sucks at handling the options property
              // You have to override the colDef valueOptions to sneak in the correct version.
              const newColDef = {
                ...(params.colDef as GridColDef),
                valueOptions: is_required
                  ? acceptable_enum_values
                  : ["", ...acceptable_enum_values],
                getOptionLabel: (value: any) => {
                  if (value === "") {
                    return (
                      <Typography
                        variant="body2"
                        color="grey"
                        sx={{ fontStyle: "italic" }}
                      >
                        Blank
                      </Typography>
                    );
                  }
                  return value;
                },
              };
              switch (data_type) {
                case "enum":
                  return (
                    <GridEditSingleSelectCell
                      {...params}
                      value={params.value}
                      disabled={!is_editable}
                      colDef={newColDef}
                    >
                      {newColDef.valueOptions?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </GridEditSingleSelectCell>
                  );

                case "boolean":
                  return (
                    <GridEditBooleanCell {...params} value={params.value} />
                  );
                case "date": {
                  const value = isValidDate(params.value)
                    ? new Date(params.value)
                    : "";
                  return (
                    <GridEditDateCell
                      {...params}
                      variant="outlined"
                      value={value}
                      type="date"
                    />
                  );
                }
                case "int":
                case "number":
                case "paragraph":
                case "string":
                case "text":
                default:
                  if (data_type === "paragraph" && params.hasFocus) {
                    return (
                      <EditTextarea
                        {...params}
                        value={params.value}
                        type="string"
                        disabled={!is_editable}
                      />
                    );
                  }
                  return (
                    <GridEditInputCell
                      {...params.props}
                      value={params.value}
                      type={
                        data_type === "int" || data_type === "number"
                          ? "number"
                          : "string"
                      }
                      disabled={!is_editable}
                      variant="outlined"
                      multiline={data_type === "paragraph"}
                      fullWidth
                      {...params}
                      inputProps={
                        data_type === "int" || data_type === "number"
                          ? { step: 1 }
                          : undefined
                      }
                      sx={{
                        ...(!is_editable && {
                          backgroundColor: "#f0f0f0", // Light gray background to indicate disabled state
                          color: "#a0a0a0", // Dim text color for disabled input
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d0d0", // Lighter border for disabled state
                          },
                        }),
                      }}
                    />
                  );
              }
            },
            filterable: true,
            filterOperators: filterOperators,
            ...(type === "singleSelect" ? { valueOptions } : {}),
          };
        }),
    ];
    return customColumns;
  }, [
    buildingOptions,
    uniquePinTypeFields,
    pinTypes,
    allPinTypeFields,
    pinTypeFieldTypeOptions,
  ]);
  const detailColumns: GridColDef[] = useMemo(() => {
    const numDetailSettings = Object.values(detailSettings).filter(
      (setting) => setting.state
    ).length;
    const details: GridActionsColDef[] = [
      {
        field: "Details",
        type: "actions",
        headerName: "",
        sortable: false,
        filterable: true,
        minWidth: 30,
        width: numDetailSettings * 48,
        hideable: false,
        pinnable: true,
        renderHeaderFilter: () =>
          detailSettings.showAttachments.state ? (
            <HasAttachmentsFilter
              attachmentFilterState={attachmentFilterState}
              setAttachmentFilterState={setAttachmentFilterState}
              apiRef={apiRef}
            />
          ) : null,
        getActions: ({ row: asset }: { row: any }) => {
          const onDetailsClick = () => {
            const id = asset._id; // or params.row.id depending on your data structure
            window.open(`/assets/${id}/details`, "_blank");
          };

          const actions: JSX.Element[] = [];
          if (detailSettings.showAttachments.state) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Attachments">
                    {asset.has_attachments ? (
                      <Badge
                        badgeContent={asset.count_attachments}
                        color="primary"
                      >
                        <PhotoLibrary />
                      </Badge>
                    ) : (
                      <PhotoLibrary
                        style={{
                          color: asset.has_attachments ? "" : "darkgrey",
                        }}
                      />
                    )}
                  </Tooltip>
                }
                label="Photos"
                key={asset._id}
                onClick={(event) => handleAttachmentsPreviewOpen(event, asset)}
                color={asset.has_attachments ? "primary" : "inherit"}
              />
            );
          }

          if (detailSettings.showLocation.state) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Location">
                    {asset.is_placed_on_document || asset["level.name"] ? (
                      <Place />
                    ) : (
                      <LocationOff
                        style={{
                          color:
                            asset.is_placed_on_document || asset["level.name"]
                              ? ""
                              : "darkgrey",
                        }}
                      />
                    )}
                  </Tooltip>
                }
                label="Location"
                key={asset._id}
                onClick={(event) => handleLocationPreviewOpen(event, asset)}
                color={
                  asset.is_placed_on_document || asset["level.name"]
                    ? "primary"
                    : "inherit"
                }
              />
            );
          }

          if (detailSettings.showAssociations?.state) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Associations">
                    {asset.has_associations ? (
                      <AccountTree />
                    ) : (
                      <AccountTree
                        style={{
                          color: asset.has_associations ? "" : "darkgrey",
                        }}
                      />
                    )}
                  </Tooltip>
                }
                label="Associations"
                key={asset._id}
                onClick={() => onDetailsClick()}
                color={asset.has_associations ? "primary" : "inherit"}
              />
            );
          }

          if (
            organization.show_condition_field &&
            detailSettings.showConditions?.state
          ) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Condition">
                    <Grading
                      style={{
                        color: asset.condition?.condition_rating
                          ? ""
                          : "darkgrey",
                      }}
                    />
                  </Tooltip>
                }
                label="Condition"
                key={asset._id}
                onClick={() => addConditionRating(asset)}
                color={
                  asset.condition?.condition_rating ? "primary" : "inherit"
                }
              />
            );
          }

          if (
            organization.show_fca &&
            flags["asset_life_cycle"] &&
            detailSettings.showTotalCostWithAdjustments?.state
          ) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Lifecycle Cost">
                    <CurrencyExchange
                      style={{
                        color: asset.costlines?.length > 0 ? "" : "darkgrey",
                      }}
                    />
                  </Tooltip>
                }
                label="Lifecycle Cost"
                key={asset._id}
                onClick={() => addLifeCycleCost(asset)}
                color={asset.costlines?.length ? "primary" : "inherit"}
              />
            );
          }
          return actions;
        },
      },
    ];
    return details;
  }, [
    detailSettings,
    attachmentFilterState,
    apiRef,
    organization.show_condition_field,
    organization.show_fca,
    flags,
    addConditionRating,
    addLifeCycleCost,
  ]);
  const createdColumns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "createdAt",
        headerName: "Created",
        renderCell: ({ row }: { row: any }) => {
          return new Date(row.createdAt).toLocaleDateString();
        },
        type: "date",
        filterable: false,
        filterOperators: getGridDateOperators(),
        minWidth: 200,
        valueGetter: (row: { createdAt: string | number | Date }) => {
          if (!row.createdAt) {
            return "";
          }
          return new Date(row.createdAt);
        },
      },
    ];
  }, []);
  const actionColumns: GridColDef[] = useMemo(() => {
    const action: GridActionsColDef[] = [
      {
        field: "Actions",
        type: "actions",
        headerName: "",
        sortable: false,
        filterable: false,
        minWidth: 80,
        hideable: false,
        pinnable: false,
        getActions: ({ row: asset }: { row: any }) => {
          const isInEditMode =
            rowModesModel[asset._id]?.mode === GridRowModes.Edit;
          const hasOtherRowInEditMode = Object.keys(rowModesModel)
            .filter((key) => key !== asset._id)
            .some((key) => rowModesModel[key]?.mode === GridRowModes.Edit);

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Save">
                    <Save
                      data-testid={`save-icon-${asset._id}`}
                      color="primary"
                    />
                  </Tooltip>
                }
                label="Save"
                key={asset._id}
                onClick={() => handleSaveClick(asset._id)}
              />,
              <GridActionsCellItem
                icon={
                  <Tooltip title="Cancel">
                    <Cancel
                      data-testid={`cancel-icon-${asset.id}`}
                      color="primary"
                    />
                  </Tooltip>
                }
                key={asset._id}
                label="Cancel"
                onClick={handleCancelClick(asset._id)}
                color="inherit"
              />,
            ];
          }
          const actions = [];
          if (assetPermissions?.update) {
            actions.push(
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit">
                    <Edit
                      data-testid={`edit-icon-${asset._id}`}
                      color={hasOtherRowInEditMode ? "disabled" : "primary"}
                    />
                  </Tooltip>
                }
                label="Edit"
                disabled={hasOtherRowInEditMode}
                key={asset._id}
                onClick={() => handleEditClick(asset._id)}
                color="inherit"
              />
            );
          }
          actions.push(
            <GridActionsCellItem
              icon={<Launch />}
              label="View Asset Details"
              key={asset._id}
              showInMenu
              onClick={() => {
                const id = asset._id; // or params.row.id depending on your data structure
                window.open(`/assets/${id}/details`, "_blank");
              }}
            />
          );
          if (permissions.canAddRequest) {
            actions.push(
              <GridActionsCellItem
                icon={<Announcement />}
                label="Add Service Request"
                key={asset._id}
                showInMenu
                onClick={() => {
                  addServiceRequest(organization, asset.building, [asset]);
                }}
              />
            );
          }
          if (permissions.canAddWorkOrder) {
            actions.push(
              <GridActionsCellItem
                icon={<Build />}
                label="Add Work Order"
                key={asset._id}
                showInMenu
                onClick={() => {
                  addWorkOrder(organization, asset.building, [asset]);
                }}
              />
            );
          }
          if (permissions.canAddSchedule) {
            actions.push(
              <GridActionsCellItem
                icon={<Event />}
                label="Add Maintenance Schedule"
                key={asset._id}
                showInMenu
                onClick={() => {
                  addMaintenanceSchedule(organization, asset.building, [asset]);
                }}
              />
            );
          }
          if (permissions.canDecommissionAsset) {
            actions.push(
              <GridActionsCellItem
                icon={<RemoveCircle sx={{ color: "red" }} />}
                label="Decommission Asset"
                key={asset._id}
                showInMenu
                onClick={() => {
                  decommissionAsset([asset]);
                }}
                sx={{ color: "red" }}
              />
            );
          }
          if (permissions.canDeleteAsset) {
            actions.push(
              <GridActionsCellItem
                icon={<Delete sx={{ color: "red" }} />}
                label="Delete Asset"
                key={asset._id}
                showInMenu
                onClick={() => {
                  deleteAsset([asset]);
                }}
                sx={{ color: "red" }}
              />
            );
          }
          return actions;
        },
      },
    ];
    return action;
  }, [
    addMaintenanceSchedule,
    addServiceRequest,
    addWorkOrder,
    assetPermissions?.update,
    decommissionAsset,
    deleteAsset,
    handleCancelClick,
    handleEditClick,
    handleSaveClick,
    organization,
    permissions.canAddRequest,
    permissions.canAddSchedule,
    permissions.canAddWorkOrder,
    permissions.canDecommissionAsset,
    permissions.canDeleteAsset,
    rowModesModel,
  ]);

  const [showCustomColumns, setShowCustomColumns] = useState(false);
  const columns: GridColDef<WebAssetResponse>[] = useMemo(() => {
    const columns = [
      ...baseColumns,
      ...(showCustomColumns ? customColumns : []),
      ...createdColumns,
      ...detailColumns,
      ...actionColumns,
    ];
    setColumnsLoaded(true);

    return columns;
  }, [
    baseColumns,
    showCustomColumns,
    customColumns,
    createdColumns,
    detailColumns,
    actionColumns,
  ]);

  const initialColumnVisibilityModel = useMemo(() => {
    const model: { [key: string]: boolean } = {}; // Add an index signature to the model object
    columns.forEach((column: any) => {
      if (
        customColumns.some(
          (customColumn) => customColumn.field === column.field
        )
      ) {
        model[column.field] = false; // Set custom columns to false
      } else {
        model[column.field] = true; // Set other columns to true
      }
    });
    return model;
  }, [columns, customColumns]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<{
    [key: string]: boolean;
  }>(initialColumnVisibilityModel);

  const handleCustomColumnsClick = useCallback(
    (state: boolean) => {
      // Need to iterate through columnVisibilityModel and set all values to false
      // that are not in BaseExportFields
      const newColumnVisibilityModel = {
        ...Object.keys(initialColumnVisibilityModel).reduce((acc, key) => {
          // if (!(BaseExportFields as Record<string, any>)[key]) {
          if (key.startsWith("values.")) {
            return { ...acc, [key]: state };
          }
          return acc;
        }, {}),
      };
      setColumnVisibilityModel(newColumnVisibilityModel);
      setShowCustomColumns(state);
    },
    [initialColumnVisibilityModel]
  );

  const {
    data: assetsQueryResponse,
    isLoading,
    isValidating,
    mutate,
  } = useInfiniteCall(
    api.web.getAssets,
    () => [
      {
        sort: sortModel.map((item) => `${item.field},${item.sort}`).join(","),
        orgId: organization._id,
        decommissioned: filters?.decommissioned,
        name: filters?.name,
        building: filters?.building,
        pinType: filters?.pinType,
        values: filters?.values,
        level: filters?.level,
        hasAttachments: filters?.has_attachments,
        conditionConditionRating: filters?.condition,
        costlinesTotalCostWithAdjustments: filters?.total_cost_with_adjustments,
        installationDate: filters?.installation_date,
        // pagination
        skip: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
      },
    ],
    uniquePinTypeFields.length > 0
  );
  useEffect(() => {
    mutate();
    if (refreshList) {
      setRefreshList(false);
    }
  }, [mutate, refreshList, setRefreshList]);

  const assets = useMemo(() => {
    if (!assetsQueryResponse || assetsQueryResponse[0] === undefined) return [];
    const flattenedItems = assetsQueryResponse.flatMap((item) => item.data);
    return flattenedItems;
  }, [assetsQueryResponse]);
  const { trigger: updateAsset } = useApiMutation(api.assets.update);

  const { data: assetsCountResponse, isLoading: isCounting } =
    makeUseServiceCall(api.web.getAssetsCount)({
      orgId: organization._id,
      decommissioned: filters?.decommissioned,
      name: filters?.name,
      building: filters?.building,
      pinType: filters?.pinType,
      values: filters?.values,
      level: filters?.level,
      hasAttachments: filters?.has_attachments,
      conditionConditionRating: filters?.condition,
      costlinesTotalCostWithAdjustments: filters?.total_cost_with_adjustments,
      installationDate: filters?.installation_date,
    });
  const assetCount = useMemo(() => {
    if (!assetsCountResponse) return 0;
    return assetsCountResponse.data.count;
  }, [assetsCountResponse]);

  useEffect(() => {
    const getColumnHeaderName = (field: string) => {
      const column = columns.find((col) => col.field === field);
      return column ? column.headerName : field;
    };
    const terms: FilterTerms[] = [];
    const filterHasChanges = filterModel?.items?.length > 0;
    setIsFilterChanged(filterHasChanges);

    filterModel?.items?.forEach(({ field, value, operator }) => {
      if (!value && operator !== "isEmpty" && operator !== "isNotEmpty") {
        return;
      }
      let description = getColumnHeaderName(field);
      let valueDescription = value;
      let operatorDescription = "";
      if (field === "building.name") {
        description = `Building`;
        valueDescription = buildings.find(
          (building) => building._id === value
        )?.name;
      } else if (field === "level.name") {
        valueDescription = orgLevels.find((level) => level._id === value)?.name;
      } else if (field === "Details") {
        description = `Has Attachments:`;
        valueDescription = `${value.charAt(0).toUpperCase() + value.slice(1)}`;
      } else if (field === "condition") {
        valueDescription = conditionOptions.find(
          (condition) => condition.value === value
        )?.label;
      }

      switch (operator) {
        case "after":
          operatorDescription = `After`;
          break;
        case "before":
          operatorDescription = `Before`;
          break;
        case "onOrAfter":
          operatorDescription = `On or After`;
          break;
        case "onOrBefore":
          operatorDescription = `On or Before`;
          break;
        case "between":
          operatorDescription = `Between`;
          break;
        case "<":
          operatorDescription = `Less Than`;
          break;
        case "<=":
          operatorDescription = `Less Than or Equal To`;
          break;
        case ">":
          operatorDescription = `Greater Than`;
          break;
        case ">=":
          operatorDescription = `Greater Than or Equal To`;
          break;
        case "isEmpty":
          operatorDescription = `Is Empty`;
          valueDescription = "";
          break;
        case "isNotEmpty":
          operatorDescription = `Is Not Empty`;
          valueDescription = "";
          break;
        default:
          operatorDescription = "";
          break;
      }

      description = `${description}: ${operatorDescription} ${valueDescription}`;
      terms.push({
        [field]: value,
        filterItem: field,
        description,
      });
    });
    setVisibleFilterTerms(terms);
  }, [buildings, filterModel, orgLevels, pinTypes, conditionOptions, columns]);

  const onAssetFilterChange = useCallback(
    async (newFilterModel: GridFilterModel) => {
      function getPinTypesIds(value: any, pinTypes: PinTypeResponse[]) {
        const pinTypeIds = pinTypes
          .filter((pinType) => pinType.name === value)
          .map((pinType) => pinType._id);
        return pinTypeIds.join(",");
      }
      const items = newFilterModel.items ? newFilterModel.items : [];
      const filters: AssetSelectionDataGridFilters = {};
      const searchParams: { [key: string]: any } = {};
      const nameFilter = items.find(({ field }) => field === "name");
      if (nameFilter && nameFilter.value) {
        filters.name = nameFilter.value;
        searchParams.name = nameFilter.value;
      }

      const buildingFilter = items.find(
        ({ field }) => field === "building.name"
      );
      if (buildingFilter && buildingFilter.value) {
        filters.building = buildingFilter.value;
        searchParams.building = buildingFilter.value;
      }

      const pinTypeFilter = items.find(({ field }) => field === "pinType.name");
      if (pinTypeFilter && pinTypeFilter.value) {
        if (
          Array.isArray(pinTypeFilter.value) &&
          pinTypeFilter.operator === "isAnyOf"
        ) {
          // For each value in the array of pinTypeFilter.value, get the id of the pinType
          // and join them together with a comma
          const ids = pinTypeFilter.value
            .map((value) => getPinTypesIds(value, pinTypes))
            .join(",");
          filters.pinType = `$in,${ids}`;
        } else {
          const ids = getPinTypesIds(pinTypeFilter.value, pinTypes);
          filters.pinType =
            pinTypeFilter.operator === "not" ? `$nin,${ids}` : `$in,${ids}`;
        }
        searchParams.pinType = filters.pinType;
      }

      const levelFilter = items.find(({ field }) => field === "level.name");
      if (levelFilter && levelFilter.value) {
        filters.level = levelFilter.value;
        searchParams.level = levelFilter.value;
      }
      const conditionFilter = items.find(({ field }) => field === "condition");
      if (conditionFilter && conditionFilter.value) {
        filters.condition = conditionFilter.value;
        searchParams.condition = filters.condition;
      }

      const installationDateFilter = items.find(
        ({ field }) => field === "installation_date"
      );
      if (installationDateFilter && installationDateFilter.value) {
        if (
          installationDateFilter.operator === "equals" ||
          installationDateFilter.operator === "is"
        ) {
          // Change equals to use $gt start of the year and $lt end of the year
          const year = installationDateFilter.value;
          const startDate = new Date(year, 0, 1).toISOString();
          const endDate = new Date(year, 11, 31).toISOString();
          filters.installation_date = `$gt,${startDate},$lt,${endDate}`;
          searchParams.installation_date = `${operatorToQuery(
            installationDateFilter.operator
          )},${installationDateFilter.value}`;
        } else {
          filters.installation_date = `${operatorToQuery(
            installationDateFilter.operator
          )},${installationDateFilter.value}`;
          searchParams.installation_date = filters.installation_date;
        }
      }

      if (
        installationDateFilter?.operator === "isEmpty" ||
        installationDateFilter?.operator === "isNotEmpty"
      ) {
        filters.installation_date = `${
          installationDateFilter?.operator === "isEmpty" ? "$eq" : "$ne"
        },null`;
        searchParams.installation_date = filters.installation_date;
      }

      const totalCostWithAdjustmentFilter = items.find(
        ({ field }) => field === "costlines.total_cost_with_adjustments"
      );
      if (
        totalCostWithAdjustmentFilter &&
        totalCostWithAdjustmentFilter.value
      ) {
        filters.total_cost_with_adjustments = `${operatorToQuery(
          totalCostWithAdjustmentFilter.operator
        )},${totalCostWithAdjustmentFilter.value}`;
        searchParams.total_cost_with_adjustments =
          filters.total_cost_with_adjustments;
      }
      if (
        totalCostWithAdjustmentFilter?.operator === "isEmpty" ||
        totalCostWithAdjustmentFilter?.operator === "isNotEmpty"
      ) {
        filters.total_cost_with_adjustments = `${operatorToQuery(
          totalCostWithAdjustmentFilter.operator
        )}`;
        searchParams.total_cost_with_adjustments =
          filters.total_cost_with_adjustments;
      }
      const attachmentsFilter = items.find(({ field }) => field === "Details");
      if (attachmentsFilter && attachmentsFilter.value) {
        filters.has_attachments = attachmentsFilter.value;
      } else {
        setAttachmentFilterState(null);
      }
      const customFields = items.filter(({ field }) =>
        field.includes("values.")
      );
      const customFieldFilters: { [key: string]: any } = {};

      // customSearchParams is an array of objects containing name and value
      const customSearchParams = customFields
        .filter(({ value }) => !!value)
        .map(({ field, value }) => {
          // If the field name starts with 'values.' then we want to remove that prefix
          if (field.startsWith("values.")) {
            field = field.replace("values.", "");
          }
          return { name: field, value };
        });
      if (customSearchParams.length > 0) {
        const customFieldSearchParam = JSON.stringify(customSearchParams);
        searchParams.pin_field = customFieldSearchParam;
      }
      customFields.reduce((acc, { field, value, operator }) => {
        // Ignore fields with empty values
        if (!value) return acc;
        const fieldName = field.split("values.")[1];
        const filterKey = fieldName;
        let filterObject = {};
        // if the value is a date we need to convert it to the time in milliseconds
        if (value instanceof Date) {
          value = value.getTime();
        }
        const queryOperator = operatorToQuery(operator);
        switch (operator) {
          case "is":
          case "equals":
          case "notEquals":
            filterObject = { [queryOperator]: value };
            break;
          case "contains":
            filterObject = { [queryOperator]: value, $options: "i" };
            break;
          case "isAnyOf":
            filterObject = { $in: value };
            break;
          default:
            filterObject = { [queryOperator]: value };
            break;
        }
        customFieldFilters[filterKey] = JSON.stringify(filterObject);
        return acc;
      }, {});

      filters.values = customFieldFilters;
      filters.decommissioned = showDecommissioned;

      setFilters(filters);
      setSelectedRows([]);
      setSearchParams(searchParams);
    },
    [pinTypes, showDecommissioned, setSearchParams]
  );
  const handleFilterModelChange = useCallback(
    (newFilterModel: GridFilterModel) => {
      setFilterModel(newFilterModel);
      onAssetFilterChange(newFilterModel);
    },
    [setFilterModel, onAssetFilterChange]
  );

  const [searchParamsLoaded, setSearchParamsLoaded] = useState(false);
  useEffect(() => {
    if (!columnsLoaded) return;
    if (!uniquePinTypeFields.length) return;
    if (searchParamsLoaded) return; // This needs to be here to prevent an infinite loop
    const filterItems: any = [];
    function addFilterItem(field: string, operator: string, value: any) {
      filterItems.push({
        field,
        operator: queryToOperator(operator),
        value,
        id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      });
    }
    if (searchParams.get("decommissioned") === "true") {
      setShowDecommissioned(true);
    }
    if (searchParams.get("name")) {
      addFilterItem("name", "contains", searchParams.get("name")?.toString());
    }
    if (searchParams.get("building")) {
      addFilterItem(
        "building.name",
        "contains",
        searchParams.get("building")?.toString()
      );
    }
    if (searchParams.get("installation_date")) {
      const installationDateParam =
        searchParams.get("installation_date")?.toString() || "";
      const [operator, installationDate] = installationDateParam.split(",");
      if (installationDate === "null") {
        addFilterItem(
          "installation_date",
          operator === "$eq" ? "isEmpty" : "isNotEmpty",
          ""
        );
      } else {
        addFilterItem("installation_date", operator, installationDate);
      }
    }
    // This is mostly for backwards compatibility with legacy asset list view
    if (searchParams.get("pin_type")) {
      // Get the pinType _id and the pinField value
      const pinType = JSON.parse(searchParams.get("pin_type") || "{}");
      // Look up pinType name by _id
      const pinTypeName = pinTypes.find(
        (type) => type._id === pinType._id
      )?.name;
      addFilterItem("pinType.name", "$in", pinTypeName);
      if (pinType.pin_fields) {
        const pinFields = pinType.pin_fields;
        for (const pinField of pinFields) {
          const pinFieldName = allPinTypeFields.find(
            (field) => field._id === pinField._id
          )?.name;
          if (pinFieldName) {
            addFilterItem(`values.${pinFieldName}`, "contains", pinField.value);
          }
        }
      }
    }
    if (searchParams.get("level")) {
      addFilterItem("level.name", "contains", searchParams.get("level"));
    }
    if (searchParams.get("pinType")) {
      const pinType = searchParams.get("pinType")?.toString() || "";
      const [operator, ...value] = pinType.split(",");
      if (!value) return;
      const namedValues = Array.from(
        new Set(
          value
            ?.map((id) => pinTypes.find((type) => type._id === id)?.name)
            .filter(Boolean)
        )
      );
      if (namedValues.length > 1) {
        addFilterItem("pinType.name", "isAnyOf", namedValues);
      } else {
        addFilterItem("pinType.name", operator, namedValues[0]);
      }
    }
    if (searchParams.get("pin_field")) {
      const pinFields = JSON.parse(searchParams.get("pin_field") || "[]");
      for (const pinField of pinFields) {
        addFilterItem(
          `values.${pinField.name}`,
          pinField.operator,
          pinField.value
        );
      }
    }
    if (searchParams.get("condition")) {
      addFilterItem(
        "condition",
        "is",
        searchParams.get("condition")?.toString() || ""
      );
    }
    if (searchParams.get("total_cost_with_adjustments")) {
      const totalCostWithAdjustments = searchParams
        .get("total_cost_with_adjustments")
        ?.toString();
      if (!totalCostWithAdjustments) return;
      const [operator, value] = totalCostWithAdjustments.split(",");
      if (operator === "$exists") {
        addFilterItem(
          "costlines.total_cost_with_adjustments",
          value === "true" ? "Is Not Empty" : "Is Empty",
          ""
        );
      } else {
        // const [operator, value] = totalCostWithAdjustments.split(",");
        addFilterItem("costlines.total_cost_with_adjustments", operator, value);
      }
    }
    handleFilterModelChange({
      items: filterItems,
    });
    setSearchParamsLoaded(true);
  }, [
    handleFilterModelChange,
    searchParams,
    searchParamsLoaded,
    setFilterModel,
    columns,
    columnsLoaded,
    location.search,
    uniquePinTypeFields,
    pinTypes,
    allPinTypeFields,
  ]);

  // Manage filters
  const handleToggleHeaderFilters = () => {
    setShowHeaderFilters((prev) => !prev);
  };

  const handleColumnVisibilityChange = (newModel: {
    [key: string]: boolean;
  }) => {
    const hasDetailSettingsEnabled = Object.values(detailSettings).some(
      (setting) => setting.state
    );
    setColumnVisibilityModel((prevModel) => {
      const hasChanges = Object.keys(newModel).some(
        (key) => newModel[key] !== prevModel[key]
      );
      return {
        ...(hasChanges ? newModel : prevModel),
        Details: hasDetailSettingsEnabled,
      };
    });
  };

  useEffect(() => {
    // Detect if there any changes in the detailSettings
    // and if there are 1 or more setting enabled, make sure that
    // the Details column is visible
    // and if there are 0 settings enabled, make sure that the Details column is hidden
    const hasDetailSettingsEnabled = Object.values(detailSettings).some(
      (setting) => setting.state
    );
    if (hasDetailSettingsEnabled) {
      setColumnVisibilityModel((prevModel) => {
        return {
          ...prevModel,
          Details: true,
        };
      });
    } else {
      setColumnVisibilityModel((prevModel) => {
        return {
          ...prevModel,
          Details: false,
        };
      });
    }
  }, [detailSettings]);

  function handleRowModesModelChange(rowModesModel: GridRowModesModel): void {
    setRowModesModel(rowModesModel);
  }
  const handleRowEditStart = useCallback(
    (params: GridRowEditStartParams, event: MuiEvent) => {
      const hasOtherRowInEditMode = Object.keys(rowModesModel)
        .filter((key) => key !== params.row._id)
        .some((key) => rowModesModel[key]?.mode === GridRowModes.Edit);
      if (
        !assetPermissions.update ||
        params.reason === GridRowEditStartReasons.printableKeyDown ||
        (params.reason === GridRowEditStartReasons.cellDoubleClick &&
          hasOtherRowInEditMode)
      ) {
        event.defaultMuiPrevented = true;
      }
    },
    [assetPermissions.update, rowModesModel]
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params: GridRowEditStopParams,
    event: MuiEvent
  ) => {
    if (
      params.reason === GridRowEditStopReasons.rowFocusOut ||
      params.reason === GridRowEditStopReasons.enterKeyDown
    ) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleProcessRowUpdate = useCallback(
    async (newValues: WebAssetResponse, oldValues: WebAssetResponse) => {
      if (oldValues.name !== newValues.name) {
        await updateAsset({
          args: {
            buildingId: newValues.building._id,
            assetId: newValues._id,
            asset: {
              name: newValues.name,
            },
          },
        });

        mutate(
          (current) => {
            if (!current) return;
            const updatedCurrent = current.map((asset) => {
              const data = asset.data.map((item) => {
                if (item._id === newValues._id) {
                  return {
                    ...item,
                    ...{ name: newValues.name, display_name: newValues.name },
                  };
                }
                return item;
              });

              return {
                ...asset,
                data,
              };
            });

            return [...updatedCurrent];
          },
          { revalidate: false }
        );

        setOpenToast(true);
        setToastMessage(
          `${oldValues.name}'s name updated from ${oldValues.name} to ${newValues.name}!`
        );
      }

      if (oldValues.level?._id !== newValues.level?._id) {
        await updateAsset({
          args: {
            buildingId: newValues.building._id,
            assetId: newValues._id,
            asset: {
              action: "updateLevel",
              level: newValues.level?._id,
            },
          },
        });

        mutate(
          (current) => {
            if (!current) return;
            const updatedCurrent = current.map((asset) => {
              const data = asset.data.map((item) => {
                if (item._id === newValues._id) {
                  return { ...item, ...{ level: newValues.level } };
                }
                return item;
              });

              return {
                ...asset,
                data,
              };
            });

            return [...updatedCurrent];
          },
          { revalidate: false }
        );
        setOpenToast(true);
        setToastMessage(
          `${oldValues.name}'s floor updated from ${oldValues.level?.name} to ${newValues.level?.name}!`
        );
      }
      function getInstallationYear(date: string | undefined) {
        return date ? new Date(date).getFullYear().toString() : "None";
      }
      if (
        getInstallationYear(oldValues.installation_date) !==
        getInstallationYear(newValues.installation_date)
      ) {
        const installationDateEpoch = new Date(
          newValues.installation_date
            ? new Date(newValues.installation_date)
            : ""
        ).getTime();

        await updateAsset({
          args: {
            buildingId: newValues.building._id,
            assetId: newValues._id,
            asset: {
              installation_date: installationDateEpoch,
            },
          },
        });

        mutate(
          (current) => {
            if (!current) return;
            const updatedCurrent = current.map((asset) => {
              const data = asset.data.map((item) => {
                if (item._id === newValues._id) {
                  return {
                    ...item,
                    ...{ installation_date: newValues.installation_date },
                  };
                }
                return item;
              });

              return {
                ...asset,
                data,
              };
            });

            return [...updatedCurrent];
          },
          { revalidate: false }
        );
        setOpenToast(true);
        setToastMessage(
          `${
            oldValues.name
          }'s installation date updated from ${getInstallationYear(
            oldValues.installation_date
          )} to ${getInstallationYear(newValues.installation_date)}!`
        );
      }

      // do a json diff of the values object and if there are differences we'll need to update the asset
      const oldValuesString = JSON.stringify(oldValues.values);
      const newValuesString = JSON.stringify(newValues.values);
      if (oldValuesString !== newValuesString) {
        const diff = Object.keys(newValues.values).reduce((acc, key) => {
          if (
            key &&
            oldValues.values[key] &&
            oldValues.values[key].value !== newValues.values[key].value
          ) {
            acc[oldValues.values[key]._id as string] =
              newValues.values[key].value;
          }
          return acc;
        }, {} as { [key: string]: any });

        if (Object.keys(diff).length > 0) {
          await updateAsset({
            args: {
              buildingId: newValues.building._id,
              assetId: newValues._id,
              asset: {
                action: "updateValues",
                values: diff,
              },
            },
          })
            .then(() => {
              setOpenToast(true);
              setToastMessage(`${oldValues.name}'s values updated!`);
            })
            .catch((err) => {
              setRowModesModel((prevModel) => ({
                ...prevModel,
                [oldValues._id]: { mode: GridRowModes.Edit },
              }));
              throw err;
            });

          mutate(
            (current) => {
              if (!current) return;
              const updatedCurrent = current.map((asset) => {
                const data = asset.data.map((item) => {
                  if (item._id === newValues._id) {
                    return {
                      ...item,
                      ...{
                        values: {
                          ...item.values,
                          ...newValues.values,
                        },
                      },
                    };
                  }
                  return item;
                });

                return {
                  ...asset,
                  data,
                };
              });

              return [...updatedCurrent];
            },
            { revalidate: false }
          );
        }
      }
      return newValues;
    },
    [mutate, setOpenToast, setToastMessage, setRowModesModel, updateAsset]
  );

  useEffect(() => {
    // Detect if there are any changes in search terms or column visibility
    const filterHasChanges = filterModel?.items?.length > 0;
    const columnVisibilityHasChanges = Object.keys(columnVisibilityModel).some(
      (key) => !columnVisibilityModel[key]
    );
    setIsFilterChanged(filterHasChanges || columnVisibilityHasChanges);
    const baseExportColumns = Object.keys(initialColumnVisibilityModel)
      .filter((key) => columnVisibilityModel[key] !== false)
      .reduce<string[]>((acc, key) => {
        if ((BaseExportFields as Record<string, any>)[key]) {
          acc.push((BaseExportFields as Record<string, any>)[key]);
        }
        if ((BaseExportFieldIds as Record<string, any>)[key]) {
          acc.push((BaseExportFieldIds as Record<string, any>)[key]);
        }
        if ((BaseExportExtended as Record<string, any>)[key]) {
          // Note this is an array of strings
          acc.push(...(BaseExportExtended as Record<string, any>)[key]);
        }
        return acc;
      }, []);

    const exportColumns = Object.keys(initialColumnVisibilityModel)
      .filter((key) => columnVisibilityModel[key] !== false)
      .flatMap((column) => {
        return pinTypes.flatMap((pinType) =>
          pinType.fields
            .filter((field) => field.name === column.replace("values.", ""))
            .map((field) => field.excel_col_name || column)
        );
      })
      .filter((value, index, self) => self.indexOf(value) === index);
    if (columnVisibilityHasChanges) {
      setExportColumns([...baseExportColumns, ...exportColumns]);
    } else {
      setExportColumns([]);
    }
  }, [
    filterModel,
    columnVisibilityModel,
    initialColumnVisibilityModel,
    pinTypes,
  ]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  let clickCount = 0;
  const clickTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [selectedRows, setSelectedRows] = React.useState<WebAssetResponse[]>(
    []
  );
  // const [selectAllRows, setSelectAllRows] = React.useState(false);
  const [selectAllDialogOpen, setSelectAllDialogOpen] = React.useState(false);
  const handleSelectAllDialogOpen = () => {
    setSelectAllDialogOpen(true);
  };
  const handleSelectAllDialogClose = () => {
    setSelectAllDialogOpen(false);
  };
  const handleSelectAllRowsConfirm = () => {
    handleSelectAllRows();
    handleSelectAllDialogClose();
  };
  const handleSelectAllRows = useCallback(async () => {
    let allRows: WebAssetResponse[] = selectedRows;
    while (allRows.length < assetCount) {
      // Fetch additional page of assets and increment pageCount
      const { data: assetRows } = await api.web.getAssets({
        orgId: organization._id,
        decommissioned: filters?.decommissioned,
        name: filters?.name,
        building: filters?.building,
        pinType: filters?.pinType,
        values: filters?.values,
        level: filters?.level,
        hasAttachments: filters?.has_attachments,
        skip: allRows.length,
        limit: 100,
      });
      allRows = [...allRows, ...assetRows];
    }
    setSelectedRows(allRows);
    // show a toast saying how many rows were selected
    setOpenToast(true);
    setToastMessage(`${allRows.length} rows selected!`);
  }, [
    selectedRows,
    assetCount,
    organization._id,
    filters?.decommissioned,
    filters?.name,
    filters?.building,
    filters?.pinType,
    filters?.values,
    filters?.level,
    filters?.has_attachments,
  ]);

  const handleRowClick = (params: any, event: React.MouseEvent) => {
    clickCount += 1;

    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    clickTimeoutRef.current = setTimeout(() => {
      if (clickCount === 1) {
        if (event.altKey || event.ctrlKey || event.metaKey) {
          handleSelectionModelChange([
            ...selectedRows.map((row) => row._id),
            params.row._id,
          ]);
        } else if (event.shiftKey) {
          // Handle shift select
          const selectedRowIds = selectedRows.map((row) => row._id);
          const lastSelectedRowId = selectedRowIds[selectedRowIds.length - 1];
          const lastSelectedRowIndex = assets.findIndex(
            (row) => row._id === lastSelectedRowId
          );
          const clickedRowIndex = assets.findIndex(
            (row) => row._id === params.row._id
          );
          const start = Math.min(lastSelectedRowIndex, clickedRowIndex);
          const end = Math.max(lastSelectedRowIndex, clickedRowIndex);
          const newSelectedRows = assets.slice(start, end + 1);
          setSelectedRows(newSelectedRows);
        } else {
          // Handle single select
          setSelectedRows([params.row]);
          setDrawerOpen(true);
        }
      }
      clickCount = 0;
    }, 500); // Adjust the timeout duration as needed
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    if (
      newSelectionModel.length < selectedRows.length &&
      selectedRows.length === assetCount
    ) {
      setSelectedRows([]);
      return;
    }
    const newSelected = assets.filter((row) =>
      newSelectionModel.includes(row._id)
    );
    const combinedSelected = [...selectedRows, ...newSelected];

    // Remove selected that are NOT in the new selection model
    const selected = combinedSelected.filter((row) =>
      newSelectionModel.includes(row._id)
    );
    // Remove duplicates based on _id
    const uniqueSelected = selected.filter(
      (row, index, self) => index === self.findIndex((r) => r._id === row._id)
    );
    setSelectedRows(uniqueSelected);
    if (newSelected.length === paginationModel.pageSize) {
      handleSelectAllDialogOpen();
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedRows([]);
  };

  return (
    <Box css={ss.dataGridWrapper}>
      <ActiveViewToolbar
        organizationId={organization._id}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialColumnVisibilityModel={initialColumnVisibilityModel}
        filterModel={filterModel}
        visibleFilterTerms={visibleFilterTerms}
        isFilterChanged={isFilterChanged}
        setFilterModel={handleFilterModelChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        setShowCustomColumns={setShowCustomColumns}
        setOpenToast={setOpenToast}
        setToastMessage={setToastMessage}
        showDecommissioned={showDecommissioned}
      />
      <Box css={ss.container}>
        <DataGridPro
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, "name"],
              right: ["Details", "Actions"],
            },
            columns: {
              columnVisibilityModel: initialColumnVisibilityModel,
            },
          }}
          apiRef={apiRef}
          getRowId={(row) => row._id}
          rows={assets}
          columns={columns}
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          autoHeight
          rowCount={assetCount}
          loading={
            isLoading ||
            isValidating ||
            isCounting ||
            !(uniquePinTypeFields.length > 0)
          }
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={allowedPageSizes}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityChange}
          filterDebounceMs={700}
          slots={{
            toolbar: () => (
              <GridToolbar
                showHeaderFilters={showHeaderFilters}
                onToggleHeaderFilters={handleToggleHeaderFilters}
                showCustomColumns={showCustomColumns}
                onToggleCustomColumns={handleCustomColumnsClick}
                checkboxSetting={checkboxVisible}
                onToggleCheckboxSetting={handleToggleCheckboxSetting}
                onExportClick={() => setExportDrawerOpen(true)}
                detailSettings={detailSettings}
                onDetailSettingChange={updateSetting}
                sidebarSetting={sidebarOpen}
                onSidebarSettingChange={updateSidebarSetting}
                showDecommissioned={showDecommissioned}
                onDecommissionedSettingChange={updateDecommissionedSetting}
              />
            ),
            pagination: () => (
              <ListPagination
                handleRefresh={() => {
                  mutate();
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            ),
          }}
          headerFilters={showHeaderFilters}
          onRowClick={handleRowClick}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(err) => {
            const message = err?.response?.data?.error?.properties
              ? err.response.data.error.properties.errors[0].message
              : err.response.data.error.message;
            setToastMessage(message);
            setOpenToast(true);
          }}
          checkboxSelection={checkboxVisible}
          indeterminateCheckboxAction="deselect"
          keepNonExistentRowsSelected
          rowSelectionModel={selectedRows.map((row) => row._id)}
          onRowSelectionModelChange={(
            newSelectionModel: GridRowSelectionModel
          ) => {
            handleSelectionModelChange(newSelectionModel);
          }}
        />
        {/* </Box> */}
        {selectedRows.length > 0 && sidebarOpen && !exportDrawerOpen ? (
          <Box css={ss.sideDrawerContainer}>
            <SideDrawer
              open={drawerOpen}
              onClose={handleDrawerClose}
              rows={selectedRows}
            />
          </Box>
        ) : null}
        {exportDrawerOpen ? (
          <Box css={ss.sideDrawerContainer}>
            <ExportDrawer
              open={exportDrawerOpen}
              onClose={() => {
                setExportDrawerOpen(false);
              }}
              filters={filters}
              organization={organization}
              exportColumns={exportColumns}
            />
          </Box>
        ) : null}
      </Box>
      {currentPreview === "attachments" && (
        <AttachmentsPreview
          asset={attachmentAsset}
          anchorEl={anchorEl}
          onClose={handleAttachmentsPreviewClose}
        />
      )}
      {currentPreview === "location" && (
        <LocationPreview
          asset={locationAsset}
          assets={[locationAsset]}
          anchorEl={anchorEl}
          onClose={handleLocationPreviewClose}
        />
      )}
      <Dialog
        open={selectAllDialogOpen}
        onClose={handleSelectAllDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select All Rows</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`All ${selectedRows.length} rows on this page are selected. Select all ${assetCount} rows?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSelectAllDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSelectAllRowsConfirm}
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={(_event: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === "clickaway") {
            return;
          }

          setOpenToast(false);
        }}
        message={toastMessage}
      ></Snackbar>
    </Box>
  );
};
