(function () {
  /**
   * @ngdoc component
   * @name abxAppSwitcher
   *
   * @description
   * A component to be used in conjunction with the app header to switch the current app
   */
  angular
    .module("akitabox.ui.components.appHeader")
    .component("abxAppSwitcher", {
      bindings: {
        appTitle: "<abxAppTitle",
        isMobile: "<abxIsMobile",
        isSuperAdmin: "<abxIsSuperAdmin",
        organization: "<abxOrganization",
      },
      controller: AbxAppSwitcherController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/app-header/components/app-switcher/app-switcher.component.html",
    });

  /* @ngInject */
  function AbxAppSwitcherController(
    // Angular
    $location,
    // Services
    EnvService,
    UserService,
    RedirectService,
    FeatureFlagService
  ) {
    var self = this;

    // =================
    // Attributes
    // =================
    self.isOpen = false;
    var permissions = UserService.getPermissions();

    var showCm = self.organization ? self.organization.show_rpi_fci : false;
    var catalogsFlagEnabled = FeatureFlagService.isEnabled("catalogs") || false;
    var accountSettingsUIEnabled =
      FeatureFlagService.isEnabled("account-settings-ui") || false;
    self.menuItems = [
      {
        title: "ListView",
        dest: getUrl("buildings"),
      },
    ];

    self.$onInit = function () {
      // Can only access the app with all 'rpam' permissions
      if (
        showCm &&
        permissions.assembly.rpam &&
        permissions.condition.rpam &&
        permissions.cost_line.rpam
      ) {
        self.menuItems.splice(2, 0, {
          title: "Capital Management",
          dest: getUrl("cm"),
          targetSelf: true,
        });
      }
      // Only admins can access accounts
      if (
        self.organization &&
        self.organization.customer_account &&
        permissions.is_administrator &&
        accountSettingsUIEnabled
      ) {
        self.menuItems.splice(2, 0, {
          title: "Account Settings",
          dest: getUrl("customer-account-settings"),
          targetSelf: true,
        });
      }
      // Only admins can access org settings
      if (permissions.is_administrator) {
        self.menuItems.splice(2, 0, {
          title: "Organization Settings",
          dest: getUrl("org-settings"),
          targetSelf: true,
        });
      }
      if (self.organization) {
        self.menuItems.splice(1, 0, {
          title: "PlanView",
          dest: getUrl("plan/buildings"),
        });
        if (self.appTitle === "ListView" || self.appTitle === "PlanView") {
          self.menuItems.push({
            title: "Use Mobile Site",
            onClick: goToMobile,
          });
        }
      }
      if (self.isSuperAdmin) {
        self.menuItems.push({
          title: "Site Admin",
          dest: getUrl("site-admin"),
          targetSelf: true,
        });
      }
      if (catalogsFlagEnabled) {
        self.menuItems.push({
          title: "Catalogs",
          dest: getUrl("catalogs"),
          targetSelf: true,
        });
      }
    };

    // =================
    // Public Functions
    // =================

    self.toggleOpen = function (state) {
      if (state) {
        self.isOpen = state;
      } else {
        self.isOpen = !self.isOpen;
      }
    };

    // =================
    // Private Functions
    // =================

    function goToMobile() {
      RedirectService.redirectToMobile($location.path());
    }

    /**
     * Enable link to CM if the Org has it enabled, or we want to show the 'Call to Action' page
     */
    function getUrl(page) {
      var url;
      var subdomain = EnvService.getSubdomain();
      switch (page) {
        case "site-admin":
          url = EnvService.getSiteAdminUrl();
          break;
        case "cm":
          url = EnvService.getCmUrl("/cm", subdomain);
          break;
        case "customer-account-settings":
          url = EnvService.getCustomerAccountSettingsUrl(
            "/account_settings",
            self.organization.customer_account
          );
          break;
        case "org-settings":
          url = EnvService.getOrgSettingsUrl(
            "/organization/settings",
            subdomain
          );
          break;
        case "catalogs":
          url = EnvService.getCatalogsRoute("/catalogs/");
          break;
        default:
          url = EnvService.getBaseUrl(page, self.isMobile, subdomain);
      }
      return url;
    }
  }
})();
